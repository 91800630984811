/* eslint-disable max-len */
import { i18n } from 'next-i18next';
import queryString from 'query-string';

import { SORT_POST_VALUES } from '@/constants';

import { createServiceStrapiToken } from '../axios';

const strapiBaseUrl = process.env.APP_STRAPI_URL;
const strapiToken = process.env.APP_STRAPI_TOKEN;
const strapiInstance = createServiceStrapiToken(strapiBaseUrl, strapiToken);

export const uploadFiles = async (formData) => {
  const response = await strapiInstance.post(`/api/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const submitFormContact = async (data) => {
  const response = await strapiInstance.post(`/api/contact-uses`, { data });
  return response?.data;
};

export const changeSlug = async (data) => {
  const response = await strapiInstance.post(`/api/route/change-slug`, data);
  return response?.data;
};

export const getNewsPage = async ({ type, sort, page, pageSize, slug, lang }) => {
  const data = queryString.stringify({
    'populate[previewImage][fields][0]': 'url',
    'populate[previewBanner][fields][0]': 'url',
    'filters[type][$eq]': type,
    'filters[slug][$ne]': slug,
    'sort[0]': sort || SORT_POST_VALUES.desc,
    'pagination[page]': page || 1,
    'pagination[pageSize]': pageSize || 6,
    lang,
  });
  const response = await strapiInstance.get(`/api/news?${data}`);
  return response?.data;
};

export const getBanner = async ({ type = 'NEW', locale }) => {
  const data = queryString.stringify({
    'populate[image][fields][0]': 'url',
    'filters[type][$eq]': type,
    'fields[0]': 'image',
    'fields[1]': 'name',
    'sort[0]': 'createdAt:desc',
    'fields[2]': 'description',
    'fields[3]': 'device',
    locale,
  });
  const response = await strapiInstance.get(`/api/banners?${data}`);
  return response?.data?.data;
};

export const getFooter = async (lang) => {
  const response = await strapiInstance.get(`/api/footers?populate[0]=footer_urls&locale=${lang}`);
  return response?.data?.data;
};

export const getHighlightContents = async ({ type, page, pageSize, slug, locale }) => {
  const data = queryString.stringify({
    'filters[slug][$ne]': slug,
    'filters[type][$eq]': type,
    'populate[previewImage][fields][0]': 'url',
    'pagination[page]': page || 1,
    'pagination[pageSize]': pageSize || 7,
    'filters[highlight][$eq]': true,
    'sort[0]': 'createdAt:desc',
    'populate[previewBanner][fields][0]': 'url',
    locale,
  });
  const response = await strapiInstance.get(`/api/news?${data}`);
  return response?.data;
};

export const getRelatedContents = async ({ slug, type, page, pageSize, locale }) => {
  const data = queryString.stringify({
    'filters[slug][$ne]': slug,
    'filters[type][$eq]': type,
    'populate[previewImage][fields][0]': 'url',
    'pagination[page]': page || 1,
    'sort[0]': 'createdAt:desc',
    'pagination[pageSize]': pageSize || 7,
    locale,
  });
  const response = await strapiInstance.get(`/api/news?${data}`);
  return response?.data;
};

export const getProducts = async ({ slug, locale }) => {
  const data = queryString.stringify({
    'filters[slug][$eq]': slug,
    'populate[banners][populate][image][fields][0]': 'url',
    'populate[banners][populate][buttons]': '*',
    'populate[overview][populate][image][fields][0]': 'url',
    'populate[features][populate][image][fields][0]': 'url',
    'populate[usecases][populate][image][fields][0]': 'url',
    'populate[benefits][populate][image][fields][0]': 'url',
    'populate[service_prices][populate][prices]': '*',
    'populate[reasons][populate][image][fields][0]': 'url',
    'populate[faqs]': '*',
    'populate[service_categories]': '*',
    'populate[action][populate][button]': '*',
    'populate[experience][populate][experiences]': '*',
    'populate[serviceRelated][populate][previewImage][fields][0]': '*',
    'populate[formReasonChoose][populate][banner][fgetDocumentHomePagesields][0]': 'url',
    'populate[serviceCertificates][populate][image][fields][0]': 'url',
    locale,
  });
  const response = await strapiInstance.get(`/api/service-details?${data}`);
  return response?.data?.data;
};

export const getProductTypes = async ({ locale } = {}) => {
  const data = queryString.stringify({
    'populate[products][populate][product_type][fields][0]': 'name',
    'populate[products][populate][previewImage][fields][0]': 'url',
    'populate[products][populate][prices][fields][0]': '*',
    locale,
  });
  const response = await strapiInstance.get(`/api/product-types?${data}`);
  return response?.data?.data;
};

export const getBannerPage = async ({ type, locale }) => {
  const data = queryString.stringify({
    'filters[type][$eq]': type || 'HEADER',
    'populate[image][fields][0]': 'url',
    'populate[buttons]': '*',
    'populate[widgets]': '*',
    locale,
  });
  const response = await strapiInstance.get(`/api/banners?${data}`);
  return response?.data;
};

export const getDocumentEbook = async (locale) => {
  const data = queryString.stringify({
    'populate[ebooks][populate][file][fields][0]': 'url',
    locale,
  });
  const response = await strapiInstance.get(`/api/ebook-types?${data}`);
  return response?.data?.data;
};

export const getTermsUse = async (locale) => {
  const data = queryString.stringify({
    'populate[policies][populate][file][fields][0]': 'url',
    locale,
  });
  const response = await strapiInstance.get(`api/terms?${data}`);
  return response?.data?.data;
};

export const getPolicy = async (locale) => {
  const data = queryString.stringify({
    'populate[policies][populate][file][fields][0]': 'url',
    locale,
  });
  const response = await strapiInstance.get(`api/policies?${data}`);
  return response?.data?.data;
};

export const getSolution = async (locale) => {
  const data = queryString.stringify({
    locale,
  });
  const response = await strapiInstance.get(`/api/homepage-solutions?${data}`);
  return response?.data?.data;
};

export const filterProductByName = async (productName) => {
  const data = queryString.stringify({
    'filters[name][$contains]': productName,
    locale: i18n.language,
  });
  const response = await strapiInstance.get(`/api/service-details?${data}`);
  return response?.data?.data;
};

export const searchDocumentByValue = async (searchValue, page, pageSize) => {
  const data = queryString.stringify({
    'filters[content][$contains]': searchValue,
    'populate[service]': '*',
    'pagination[page]': page || 1,
    'pagination[pageSize]': pageSize || 5,
    'populate[document_type][fields][0]': 'slug',
    locale: i18n.language,
  });
  const response = await strapiInstance.get(`/api/document-pages?${data}`);
  return response?.data;
};

export const searchInfoByKeyWord = async ({ searchValue, page, pageSize, searchBy }) => {
  const data = queryString.stringify({
    'filters[$or][0][name][$contains]':
      searchBy === 'service-details' || searchBy === 'policy-pages' ? searchValue : undefined,
    'filters[$or][1][previewContent][$contains]':
      searchBy === 'service-details' ? searchValue : undefined,
    'filters[$or][0][title][$contains]':
      searchBy === 'new-details' || searchBy === 'ebooks' ? searchValue : undefined,
    'filters[$or][1][content][$contains]':
      searchBy === 'new-details' || searchBy === 'ebooks' ? searchValue : undefined,
    'pagination[page]': page || 1,
    'pagination[pageSize]': pageSize || 5,
    'filters[content][$contains]': searchBy === 'document-pages' ? searchValue : undefined,
    'populate[service]': searchBy === 'document-pages' ? '*' : undefined,
    'populate[document_type][fields][0]': searchBy === 'document-pages' ? 'slug' : undefined,
    locale: i18n.language,
  });
  const response = await strapiInstance.get(`/api/${searchBy}?${data}`);
  return response?.data;
};

export const getDocumentTypes = async ({ productSlug, locale }) => {
  const data = queryString.stringify({
    'filters[document_pages][service][slug][$eq]': productSlug,
    'sort[0]': 'rank%3Aasc',
    locale,
  });
  const response = await strapiInstance.get(`/api/document-types?${data}`);
  return response?.data?.data;
};
export const getPolicySidebar = async ({ locale }) => {
  const data = queryString.stringify({
    'populate[policy_types][populate][policy_details]': '*',
    'sort[0]': 'rank%3Aasc',
    locale,
  });
  const response = await strapiInstance.get(`/api/policy-categories?${data}`);
  return response?.data?.data;
};

export const getHomePagePolicy = async ({ locale }) => {
  const data = queryString.stringify({
    'populate[policy][populate]': '*',
    locale,
  });
  const response = await strapiInstance.get(`/api/homepage-policies?${data}`);
  return response?.data?.data;
};

export const getPageDocument = async ({ featureSlug, productSlug, locale }) => {
  const data = queryString.stringify({
    'filters[slug][$eq]': featureSlug,
    'populate[highlight]': '*',
    'populate[file][fields][0]': 'url',
    'populate[service]': '*',
    'filters[service][slug][$eq]': productSlug,
    'populate[document_type][fields][0]': '',
    locale,
  });
  const response = await strapiInstance.get(`/api/document-pages?${data}`);
  return response?.data?.data;
};

export const getPolicyCategories = async ({ categorySlug, locale }) => {
  const data = queryString.stringify({
    'filters[slug][$eq]': categorySlug,
    'populate[policy_page][populate][file]': '*',
    locale,
  });
  const response = await strapiInstance.get(`/api/policy-categories?${data}`);
  return response?.data?.data;
};
export const getPolicyTypeDetail = async ({ categorySlug, locale }) => {
  const data = queryString.stringify({
    'filters[slug][$eq]': categorySlug,
    'populate[policy_page][populate][file]': '*',
    locale,
  });
  const response = await strapiInstance.get(`/api/policy-types?${data}`);
  return response?.data?.data;
};
export const getPolicyDetail = async ({ categorySlug, locale }) => {
  const data = queryString.stringify({
    'filters[slug][$eq]': categorySlug,
    'populate[policy_page][populate][file]': '*',
    locale,
  });
  const response = await strapiInstance.get(`/api/policy-details?${data}`);
  return response?.data?.data;
};

export const getDocumentHomePages = async ({ productSlug, documentSlug, locale }) => {
  const data = queryString.stringify({
    'filters[service][slug][$eq]': productSlug,
    'filters[document_type][slug][$eq]': documentSlug,
    locale,
  });
  const response = await strapiInstance.get(`/api/document-homepages?${data}`);
  return response?.data?.data;
};

export const getFAQs = async ({ productName, locale }) => {
  const data = queryString.stringify({
    'populate[Question]': '*',
    'filters[product][name][$eq]': productName,
    locale,
  });
  const response = await strapiInstance.get(`/api/faqs?${data}`);
  return response?.data?.data;
};

export const getReasons = async (locale) => {
  const data = queryString.stringify({
    populate: 'reasons',
    locale,
  });
  const response = await strapiInstance.get(`/api/homepage-reasons?${data}`);
  return response?.data?.data;
};

export const getStrengths = async (locale) => {
  const data = queryString.stringify({
    'populate[strength][populate][image][fields][0]': 'url',
    'populate[banner][field][0]': 'url',
    locale,
  });
  const response = await strapiInstance.get(`/api/homepage-strengths?${data}`);
  return response?.data?.data;
};

export const getPrizes = async (locale) => {
  const data = queryString.stringify({
    'populate[prize][populate][image][fields][0]': 'url',
    locale,
  });
  const response = await strapiInstance.get(`/api/prizes?${data}`);
  return response?.data?.data;
};

export const getExperiences = async (locale) => {
  const data = queryString.stringify({
    populate: 'experiences',
    locale,
  });
  const response = await strapiInstance.get(`/api/homepage-experiences?${data}`);
  return response?.data?.data;
};

export const getCustomers = async (locale) => {
  const data = queryString.stringify({
    'populate[categories][populate][customers][populate][image][fields][0]': 'url',
    locale,
  });
  const response = await strapiInstance.get(`/api/customers?${data}`);
  return response?.data?.data;
};

export const getNavBar = async (locale) => {
  const data = queryString.stringify({
    'populate[navbar_menus][populate][category]': '*',
    'sort[0]': 'rank%3Aasc',
    locale,
  });
  const response = await strapiInstance.get(`/api/navbars?${data}`);
  return response?.data;
};
export const getStories = async (locale) => {
  const data = queryString.stringify({
    locale,
  });
  const response = await strapiInstance.get(`/api/homepage-stories?${data}`);
  return response?.data?.data;
};
export const getHomepageService = async (locale) => {
  const data = queryString.stringify({
    locale,
  });
  const response = await strapiInstance.get(`/api/homepage-services?${data}`);
  return response?.data?.data;
};

export const getHomepageDocument = async (locale) => {
  const data = queryString.stringify({
    locale,
  });
  const response = await strapiInstance.get(`/api/homepage-documents?${data}`);
  return response?.data?.data;
};

export const getCertificates = async (locale) => {
  const data = queryString.stringify({
    'populate[certificates][populate][image][fields][0]': 'url',
    locale,
  });
  const response = await strapiInstance.get(`/api/certificates?${data}`);
  return response?.data?.data;
};

export const getNewsHomePage = async (locale) => {
  const data = queryString.stringify({
    'populate[news][populate][previewImage][fields][0]': 'url',
    locale,
  });
  const response = await strapiInstance.get(`/api/homepage-news?${data}`);
  return response?.data?.data;
};

export const getNewsBannerPage = async ({ locale, type }) => {
  const data = queryString.stringify({
    'populate[image][fields][0]': 'url',
    'filters[type][$eq]': type,
    locale,
  });
  const response = await strapiInstance.get(`/api/banners?${data}`);
  return response?.data?.data;
};

export const getNewsTrends = async (locale) => {
  const data = queryString.stringify({
    locale,
  });
  const response = await strapiInstance.get(`/api/news-tags?${data}`);
  return response?.data?.data;
};

export const getNewsMostRead = async (locale) => {
  const data = queryString.stringify({
    'sort[0]': 'mostRead:desc',
    'pagination[pageSize]': 5,
    'pagination[page]': 1,
    locale,
  });
  const response = await strapiInstance.get(`/api/new-details?${data}`);
  return response?.data?.data;
};

export const getAllNews = async (locale) => {
  const data = queryString.stringify({
    'pagination[pageSize]': 500,
    'pagination[page]': 1,
    locale,
  });
  const response = await strapiInstance.get(`/api/new-details?${data}`);
  return response?.data?.data;
};

export const getNewsVideos = async (locale) => {
  const data = queryString.stringify({
    locale,
  });
  const response = await strapiInstance.get(`/api/news-videos?${data}`);
  return response?.data?.data;
};

export const getContactUs = async ({ locale }) => {
  const data = queryString.stringify({
    'populate[call]': '*',
    'populate[iconCall][fields][0]': 'url',
    'populate[mails]': '*',
    'populate[iconMail][fields][0]': 'url',
    'populate[addresses][populate][icon][fields][0]': 'url',
    'populate[iconAddress][fields][0]': 'url',
    locale,
  });
  const response = await strapiInstance.get(`/api/contacts?${data}`);
  return response?.data?.data;
};

export const getNewsCategories = async ({ locale }) => {
  const data = queryString.stringify({
    locale,
  });
  const response = await strapiInstance.get(`/api/news-categories?${data}`);
  return response?.data?.data;
};

export const getNewsAndTags = async ({ page, pageSize, categoryName, newsTag, locale }) => {
  const data = queryString.stringify({
    'filters[news_categories][name][$eq]': categoryName,
    'filters[news_tags][name][$eq]': newsTag,
    'populate[0]': 'news_categories',
    'populate[1]': 'news_tags',
    'pagination[pageSize]': pageSize,
    'pagination[page]': page,
    'sort[0]': 'updatedTime:desc',
    locale,
  });
  const response = await strapiInstance.get(`/api/new-details?${data}`);
  return response?.data;
};

export const getNewDetail = async ({ locale, title }) => {
  const data = queryString.stringify({
    'filters[slug][$eq]': title,
    'populate[highlights]': '*',
    'populate[banners][populate][image][fields][0]': 'url',
    'populate[news_tags]': '*',
    'populate[related_news][populate][previewImage][fields][0]': 'url',
    'populate[news_categories]': '*',
    'populate[previewImage][fields][0]': 'url',
    locale,
  });
  const response = await strapiInstance.get(`/api/new-details?${data}`);
  return response?.data;
};

export const getBannersNewDetail = async ({ locale }) => {
  const data = queryString.stringify({
    'filters[type][$contains]': 'new detail',
    'populate[image][fields][0]': 'url',
    locale,
  });
  const response = await strapiInstance.get(`/api/banners?${data}`);
  return response?.data;
};

export const getPromotionOver = async ({ locale }) => {
  const data = queryString.stringify({
    'populate[formReasonChoose][populate]': '*',
    locale,
  });
  const response = await strapiInstance.get(`/api/promotion-homepages?${data}`);
  return response?.data?.data;
};
export const getPromotionTrial = async ({ locale }) => {
  const data = queryString.stringify({
    'populate[promotion_trials][populate][button]': '*',
    locale,
  });
  const response = await strapiInstance.get(`/api/promotion-categories?${data}`);
  return response?.data?.data;
};
export const getPromotionCampaigns = async ({ locale }) => {
  const data = queryString.stringify({
    'populate[discounts]': '*',
    'populate[image][fields][0]': 'url',
    'sort[0]': 'pin:desc',
    locale,
  });
  const response = await strapiInstance.get(`/api/promotion-campaigns?${data}`);
  return response?.data?.data;
};

export const getDetailCampaign = async ({ locale, promotionSlug }) => {
  const data = queryString.stringify({
    'filters[slug][$eq]': promotionSlug,
    'populate[promotion_campaign_detail][populate][image][fields][0]': 'url',
    'populate[promotion_campaign_detail][populate][banners][populate][image][fields][0]': 'url',
    'populate[promotion_campaign_detail][populate][promotion_trials][populate][button]': '*',
    'populate[promotion_campaign_detail][populate][banners][populate][buttons]': '*',
    'populate[promotion_campaign_detail][populate][formReasonChoose]': '*',
    locale,
  });
  const response = await strapiInstance.get(`/api/promotion-campaigns?${data}`);
  return response?.data?.data;
};

export const getDetailVoucher = async ({ locale, promotionSlug }) => {
  const data = queryString.stringify({
    'filters[slug][$eq]': promotionSlug,
    'populate[promotion_detail][populate][content][populate][reasons][populate][image][fields][0]':
      'url',
    'populate[promotion_detail][populate][content][populate][banner][fields][0]': 'url',
    'populate[promotion_detail][populate][promotion_trials][populate][button]': '*',
    'populate[promotion_detail][populate][banners][populate][image][fields][0]': 'url',
    'populate[promotion_detail][populate][banners][populate][button]': '*',
    'populate[promotion_detail][populate][formReasonChoose]': '*',
    locale,
  });
  const response = await strapiInstance.get(`/api/promotion-campaigns?${data}`);
  return response?.data?.data;
};
export const getEbookHight = async ({ locale }) => {
  const data = queryString.stringify({
    'filters[pin][$eq]': true,
    'populate[previewImage][fields][0]': 'url',
    locale,
  });
  const response = await strapiInstance.get(`/api/ebooks?${data}`);
  return response?.data?.data;
};
export const getEbookNew = async ({ locale }) => {
  const data = queryString.stringify({
    'filters[newest][$eq]': true,
    'populate[previewImage][fields][0]': 'url',
    locale,
  });
  const response = await strapiInstance.get(`/api/ebooks?${data}`);
  return response?.data?.data;
};
export const getEbookAll = async ({ locale, page, pageSize }) => {
  const data = queryString.stringify({
    'populate[previewImage][fields][0]': 'url',
    'pagination[page]': page || 1,
    'pagination[pageSize]': pageSize || 10,
    locale,
  });
  const response = await strapiInstance.get(`/api/ebooks?${data}`);
  return response?.data?.data;
};

export const getDetailEbook = async ({ locale, ebookSlug }) => {
  const data = queryString.stringify({
    'filters[slug][$eq]': ebookSlug,
    'populate[file][fields][0]': 'url',
    'populate[banners][populate][image][fields][0]': 'url',
    'populate[new_details][populate][previewImage][fields][0]': 'url',
    'populate[service_details][populate][previewImage][fields][0]': 'url',
    'populate[formReasonChoose][populate]': '*',
    locale,
  });
  const response = await strapiInstance.get(`/api/ebooks?${data}`);
  return response?.data?.data;
};
export const getDetailEbookOther = async ({ locale, ebookSlug }) => {
  const data = queryString.stringify({
    'filters[slug][$ne]': ebookSlug,
    'populate[previewImage][fields][0]': 'url',
    locale,
  });
  const response = await strapiInstance.get(`/api/ebooks?${data}`);
  return response?.data?.data;
};

export const getAboutUs = async (locale) => {
  const data = queryString.stringify({
    'populate[areaImage][fields][0]': '*',
    'populate[aboutImage][fields][0]': 'url',
    'populate[statics]': '*',
    'populate[reason][populate][labels][populate][image][fields][0]': 'url',
    'populate[histories][populate][image][fields][0]': 'url',
    'populate[partners][populate][image][fields][0]': 'url',
    'populate[new_details][populate][previewImage][fields][0]': 'url',
    'populate[service_details][populate][previewImage][fields][0]': 'url',
    'populate[banners][populate][image][fields][0]': 'url',
    'populate[banners][populate][buttons]': '*',
    locale,
  });
  const response = await strapiInstance.get(`/api/about-uses?${data}`);
  return response?.data?.data;
};

export const getNewsByHashtag = async ({ locale, hashtagName, page, pageSize }) => {
  const data = queryString.stringify({
    'filters[news_tags][name][$eq]': hashtagName,
    'populate[previewImage][fields][0]': 'url',
    'populate[0]': 'news_categories',
    'populate[1]': 'news_tags',
    'pagination[page]': page || 1,
    'pagination[pageSize]': pageSize || 6,
    'sort[0]': 'updatedTime:desc',
    locale,
  });
  const response = await strapiInstance.get(`/api/new-details?${data}`);
  return response?.data;
};

export const getCaseStudy = async ({ locale }) => {
  const data = queryString.stringify({
    'populate[case_study_details][populate][previewImage][fields][0]': '*',
    locale,
  });
  const response = await strapiInstance.get(`/api/homepage-case-studies?${data}`);
  return response?.data;
};

export const getMoreCustomerStory = async ({
  locale,
  location,
  page,
  pageSize,
  industryName,
  serviceName,
}) => {
  const data = queryString.stringify({
    'populate[companyLogo][populate][image][fields]': 'url',
    'populate[locations]': '*',
    'filters[locations][name][$in]': location || undefined,
    'populate[industries]': '*',
    'filters[industries][name][$in]': industryName || undefined,
    'populate[service_detail]': '*',
    'filters[service_detail][name][$in]': serviceName || undefined,
    'pagination[page]': page || 1,
    'pagination[pageSize]': pageSize || 12,
    'sort[0]': 'pin:desc',
    'sort[1]': 'updateTime:desc',
    locale,
  });
  const response = await strapiInstance.get(`/api/case-study-details?${data}`);
  return response?.data;
};

export const getCustomerStoryDetail = async ({ locale, slug }) => {
  const data = queryString.stringify({
    'filters[slug][$eq]': slug || undefined,
    'populate[banner][populate][image][fields]': 'url',
    'populate[companyLogo][populate][image][fields]': 'url',
    'populate[contact_us][populate][button]': '*',
    'populate[formReasonChoose][populate]': '*',
    'populate[news_tags]': '*',
    'populate[locations]': '*',
    'populate[industries]': '*',
    'populate[related_case_studies][populate][companyLogo][fields][0]': 'url',
    locale,
  });
  const response = await strapiInstance.get(`/api/case-study-details?${data}`);
  return response?.data;
};

export const getEventList = async ({ locale, page, pageSize }) => {
  const data = queryString.stringify({
    'sort[0]': 'pin:desc',
    'sort[1]': 'eventStartTime:asc',
    'pagination[page]': page || 1,
    'pagination[pageSize]': pageSize || 6,
    'populate[previewImage][populate][image][fields]': 'url',
    locale,
  });
  const response = await strapiInstance.get(`/api/event-details?${data}`);
  return response?.data;
};

export const getEventDetail = async ({ slug, locale }) => {
  const data = queryString.stringify({
    'filters[slug][$eq]': slug || undefined,
    'populate[banner][populate][image][fields]': 'url',
    'populate[companyLogo][populate][image][fields]': 'url',
    'populate[agenda][populate]': '*',
    'populate[speaker][populate]': '*',
    'populate[reason][populate]': '*',
    'populate[reasonImage][populate][image][fields]': '*',
    'populate[organizationalUnitImage][populate][image][fields]': 'url',
    'populate[present][populate]': '*',
    'populate[customer][populate]': '*',
    'populate[form][populate]': '*',
    locale,
  });
  const response = await strapiInstance.get(`/api/event-details?${data}`);
  return response?.data;
};
