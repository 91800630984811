import { useState } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { Box, Collapse } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import InputSearch from '../InputSearch';

export default function HeaderSearch({ handleSearch, label, menu, dataTabs, handleCloseMenu }) {
  const [isShowInputSearch, setIsShowInputSearch] = useState(false);
  const theme = useTheme();

  return (
    <Box
      onMouseLeave={() => {
        setIsShowInputSearch(false);
      }}
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {menu ? (
        <InputSearch
          menu={menu}
          label={label}
          handleFetchData={handleSearch}
          handleCloseMenu={handleCloseMenu}
          dataTabs={dataTabs}
          sx={{ display: { xs: 'none', md: 'flex' } }}
        />
      ) : (
        <>
          <Collapse
            orientation="horizontal"
            in={isShowInputSearch}
            easing={{
              enter: 'cubic-bezier(0, 1.5, .8, 1)',
              exit: 'linear',
            }}
          >
            <InputSearch label={label} handleFetchData={handleSearch} />
          </Collapse>
          {!isShowInputSearch && (
            <SearchIcon
              fontSize="medium"
              sx={{ color: theme.palette.text.default }}
              onMouseEnter={() => {
                setIsShowInputSearch(true);
              }}
              onClick={() => {
                setIsShowInputSearch(true);
              }}
            />
          )}
        </>
      )}
    </Box>
  );
}
