import axios from 'axios';

import { ACCESS_TOKEN } from '@/constants';
import { get, removeAllItem } from '@/services/storage';

const baseConfig = (baseURL, contentType = 'application/json') => {
  return {
    baseURL,
    headers: {
      'Accept-Language': '*',
      'Content-Type': contentType,
      'Cache-Control': 'max-age=10',
    },
  };
};

const interceptAuth = (config) => {
  const instance = axios.create(config);
  instance.interceptors.request.use((cf) => {
    const token = get(ACCESS_TOKEN);
    if (token) {
      cf.headers.Authorization = `Bearer ${token}`;
    }
    return cf;
  });
  instance.interceptors.response.use(
    (response) => {
      // Do something with response data
      return response;
    },
    (error) => {
      if (error?.response?.status === 401) {
        removeAllItem();
        window.location.href = 'http://localhost:3000/login';
      }
      // Do something with response error
      return Promise.reject(error);
    }
  );
  return instance;
};

export const createService = (baseURL, contentType = 'application/json') => {
  return interceptAuth(baseConfig(baseURL, contentType));
};

export const downloadFileService = (baseURL, contentType = 'application/json') => {
  const config = baseConfig(baseURL, contentType);
  config.responseType = 'blob';
  return interceptAuth(config);
};

export const createServiceNoToken = (baseURL) => {
  const instance = axios.create({
    baseURL,
    headers: {
      'Accept-Language': '*',
      'Content-Type': 'application/json',
    },
  });
  instance.interceptors.request.use((config) => {
    return config;
  });
  return instance;
};

export const createServiceStrapiToken = (baseURL, token) => {
  const instance = axios.create({
    baseURL,
    headers: {
      'Accept-Language': '*',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Cache-Control': 'max-age=10',
    },
  });

  instance.interceptors.request.use((config) => {
    return config;
  });
  return instance;
};
