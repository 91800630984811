import { memo, useMemo, useState } from 'react';

import { Close } from '@mui/icons-material';
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { i18n, useTranslation } from 'next-i18next';

import HeaderSearch from '@/layouts/header/components/HeaderSearch';
import { filterProductByName } from '@/services/apis';

const a11yProps = (index) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const TabsMenu = ({ dataTabs, hrefLink, handleCloseMenu }) => {
  const router = useRouter();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t } = useTranslation(['header', 'home']);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const getColor = (badgeName) => {
    switch (badgeName) {
      case 'Hot':
        return {
          background: '#FFEEE8',
          color: '#FF521C',
        };
      case 'New':
        return {
          background: '#FFF8E6',
          color: '#CC9600',
        };
      case 'Most view':
        return {
          background: '#EDFEF0',
          color: '#3BC251',
        };
      case 'Deal':
        return {
          background: '#E9F5FF',
          color: '#1F96D2',
        };

      case 'Comming soon':
        return {
          background: '#FFF3E9',
          color: '#CC6D1A',
        };

      default:
        return {};
    }
  };

  const handleSearchProduct = async (productName) => {
    try {
      return await filterProductByName(productName);
    } catch (err) {
      return [];
    }
  };

  const memoizedProductList = useMemo(() => {
    return dataTabs?.categories?.map((cat, index) => {
      return (
        <TabPanel key={index} value={value} index={index}>
          <Grid container spacing={2} rowGap={5}>
            {cat?.items?.map((card) => (
              <Grid item md={4} mb={2} key={card?.id}>
                <Box display="flex" alignItems="start" gap={2}>
                  <Box>
                    {card?.image?.url && (
                      <Image src={card?.image?.url} width={50} height={50} alt="icon" />
                    )}
                  </Box>
                  <Box
                    sx={{
                      flexGrow: 1,
                    }}
                  >
                    <Link
                      href={`/${hrefLink}${card?.slug}`}
                      style={{
                        display: 'flex',
                        gap: 7,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 16,
                          color: theme.palette.text.blue,
                          fontWeight: 600,
                          lineHeight: '24px',
                          textDecoration: 'none',
                          cursor: 'pointer',
                          '&:hover': {
                            color: theme.palette.highlight,
                          },
                        }}
                      >
                        {card?.title}
                      </Typography>
                      <Typography
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 0.5,
                          padding: '2px 5px',
                          borderRadius: '12px',
                          ...getColor(card?.badge?.name),
                        }}
                      >
                        <Box>
                          {card?.badge?.image?.url && (
                            <Image src={card?.badge?.image?.url} alt="" width={12} height={12} />
                          )}
                        </Box>
                        <Typography component="span" fontSize={14}>
                          {card?.badge?.name}
                        </Typography>
                      </Typography>
                    </Link>
                    <Typography
                      sx={{
                        color: theme.palette.text.secondary,
                        fontSize: 14,
                        lineHeight: '24px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        WebkitLineClamp: 2,
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        textDecoration: 'none',
                      }}
                    >
                      {card?.content}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </TabPanel>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTabs, value]);

  return (
    <Box
      py={5}
      bgcolor={theme.palette.background.white}
      sx={{
        height: 'calc(100vh - 69px)',
        '@media (min-width: 1600px)': {
          height: '80vh',
        },
      }}
    >
      <Grid container spacing={2}>
        <Grid item md={3} />
        <Grid item md={8} mb={5} display="flex" gap={2} alignItems="center">
          {hrefLink === 'dich-vu' && (
            <Box width="100%">
              <HeaderSearch
                menu
                label={t('search')}
                handleSearch={handleSearchProduct}
                dataTabs={dataTabs}
                handleCloseMenu={handleCloseMenu}
              />
            </Box>
          )}
        </Grid>
        <Grid item md={1}>
          <Close sx={{ cursor: 'pointer' }} onClick={handleCloseMenu} />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          overflow: 'auto !important',
          '::-webkit-scrollbar': {
            width: '5px',
          },
          '::-webkit-scrollbar-track': {
            background: theme.palette.highlight,
          },
          '::-webkit-scrollbar-thumb': {
            bgcolor: theme.palette.highlight,
          },
          '::-webkit-scrollbar-thumb:hover': {
            background: theme.palette.background.scrollbar_thumb_hover,
          },
        }}
      >
        <Grid item md={3} borderRight="1px solid #DEE2E6" sx={{ textTransform: 'capitalize' }}>
          <Grid container>
            <Grid item md={2} lg={2} />
            <Grid item xs={12} md={10} lg={10}>
              <Tabs
                orientation="vertical"
                scrollButtons="auto"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{
                  alignItems: 'flex-end',
                  borderLeft: 1,
                  borderColor: '#868E96',
                  height: 'calc(100vh -170px)',
                  '@media (min-width: 1600px)': {
                    height: '55vh',
                  },
                  '& .MuiTabs-indicator': {
                    left: 0,
                    backgroundColor: theme.palette.highlight,
                  },
                  '& .MuiTabs-scroller': {
                    width: '100%',
                    overflow: 'auto !important',
                    '::-webkit-scrollbar': {
                      width: '3px',
                    },
                    '::-webkit-scrollbar-track': {
                      background: theme.palette.highlight,
                    },
                    '::-webkit-scrollbar-thumb': {
                      bgcolor: theme.palette.highlight,
                    },
                    '::-webkit-scrollbar-thumb:hover': {
                      background: theme.palette.background.scrollbar_thumb_hover,
                    },
                  },
                  '& .MuiButtonBase-root': {
                    alignItems: 'flex-start',
                    textAlign: 'left',
                    lineHeight: '22px',
                    px: 3,
                    py: 1,
                    width: '100%',
                    minHeight: '32px',
                    textTransform: 'initial',
                  },
                  '&:not(:last-of-type)': {
                    marginRight: 0,
                  },
                }}
              >
                {dataTabs?.categories?.map((item, index) => (
                  <Tab
                    sx={{
                      color: theme.palette.text.primary,
                      borderRadius: 'unset',
                      fontSize: 16,
                      lineHeight: '24px',
                      fontWeight: 500,
                      mb: 1,
                      marginRight: '0 !important',
                      '&.Mui-selected .service-industry': {
                        color: theme.palette.text.about_text,
                      },
                      '&:hover .service-industry': {
                        color: theme.palette.text.about_text,
                      },
                    }}
                    key={index}
                    label={
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          className="service-industry"
                          sx={{
                            fontSize: 16,
                            color: theme.palette.text.secondary,
                            fontFamily: 'SVN-Gotham, sanserif',
                            fontWeight: 450,
                            lineHeight: '24px',
                            textDecoration: 'none',
                            cursor: 'pointer',
                          }}
                        >
                          {item?.name}
                        </Typography>
                        <Typography
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                            padding: '3px 5px',
                            borderRadius: '12px',
                            ...getColor(item?.badge?.name),
                          }}
                        >
                          {item?.badge?.image?.url && (
                            <Image src={item?.badge?.image?.url} alt="" width={15} height={15} />
                          )}
                          <Typography component="span" fontSize={14}>
                            {item?.badge?.name}
                          </Typography>
                        </Typography>
                      </Box>
                    }
                    {...a11yProps(index)}
                  />
                ))}
                <Tab
                  sx={{
                    fontSize: 16,
                    fontWeight: 450,
                    color: theme.palette.text.secondary,
                    fontFamily: 'SVN-Gotham, sanserif',
                    alignItems: 'flex-start',
                    textTransform: 'inherit',
                    opacity: 1,
                  }}
                  role="menuitem"
                  label={i18n?.language === 'vi' ? 'Tất cả dịch vụ' : 'All services'}
                  onClick={() => router.push('/dich-vu')}
                />
              </Tabs>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={9}>
          {memoizedProductList}
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(TabsMenu);
