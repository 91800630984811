import { createContext, useContext } from 'react';

const ThemeContext = createContext();

export function useThemeContext() {
  return useContext(ThemeContext);
}

export default function ThemeProvider({ children, value }) {
  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
}
