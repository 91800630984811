import { useEffect, useMemo, useState } from 'react';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useRouter } from 'next/router';

import { hasChildren } from '@/utils';

const SingleLevel = ({ item = {}, parentSlug }) => {
  const router = useRouter();
  const theme = useTheme();

  const isSelectedChild = useMemo(
    () =>
      router?.query?.productId?.[Number(router?.query?.productId?.length) - 1] ===
      item?.attributes?.slug,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router?.query?.productId]
  );

  const handleClickItem = () => {
    router.push(
      `/document/${router?.query?.productId?.[0]}/${parentSlug ? `${parentSlug}/` : ''}${
        item?.attributes?.slug
      }`,
      undefined,
      { shallow: true }
    );
  };

  return (
    <ListItem>
      {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
      <Typography
        onClick={handleClickItem}
        sx={{
          color: isSelectedChild ? theme.palette.text.blue : theme.palette.text.default,
          cursor: 'pointer',
          // set font weight for parent label sidebar
          fontWeight: item?.attributes?.name ? '700' : 'inherit',
          ':hover': {
            color: theme.palette.text.blue,
          },
        }}
      >
        {item?.attributes?.title || item?.attributes?.name}
      </Typography>
    </ListItem>
  );
};

const MultiLevel = ({ item = {} }) => {
  const children = item?.attributes?.document_pages?.data || [];
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const theme = useTheme();

  const isSelectedParent = useMemo(
    () =>
      router?.query?.productId &&
      Array.isArray(router?.query?.productId) &&
      router?.query?.productId?.includes(item?.attributes?.slug),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router?.query?.productId]
  );

  // get selected item for 2 layers
  const selectedItem = children.find((child) => router.asPath?.includes(child?.attributes?.slug));
  // set open for active menu
  useEffect(() => {
    if (!open && !!selectedItem) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  const handleClick = (parentItem) => {
    setOpen(!open);
    router.push(
      `/document/${router?.query?.productId?.[0]}/${parentItem?.attributes?.slug}`,
      undefined,
      { shallow: true }
    );
  };

  return (
    <>
      <ListItem>
        {item?.attributes?.icon && <ListItemIcon>{item?.attributes?.icon}</ListItemIcon>}
        <ListItemText
          onClick={() => handleClick(item)}
          primary={item?.attributes?.name}
          sx={{
            span: { fontWeight: 700 },
            color: isSelectedParent ? theme.palette.text.blue : theme.palette.text.default,
            cursor: 'pointer',
            ':hover': {
              color: theme.palette.text.blue,
            },
          }}
        />
        {!item?.isCategory && (
          <Box
            onClick={(e) => {
              e.preventDefault();
              setOpen(!open);
            }}
          >
            {open ? (
              <ArrowDropUpIcon fontSize="x-small" />
            ) : (
              <ArrowDropDownIcon fontSize="x-small" />
            )}
          </Box>
        )}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding sx={{ paddingLeft: 3 }}>
          {children.map((child, key) => (
            <MenuItem key={key} item={child} parentSlug={item?.attributes?.slug} />
          ))}
        </List>
      </Collapse>
    </>
  );
};

const MenuItem = ({ item, parentSlug }) => {
  const Component = hasChildren(item?.attributes?.document_pages) ? MultiLevel : SingleLevel;
  return <Component item={item} isCategory parentSlug={parentSlug} />;
};

export default MenuItem;
