import { Box } from '@mui/material';
import Image from 'next/image';

import backToTop from '@/assets/icons/ToTop.png';
import palette from '@/theme/palette';
import { scrollToTop } from '@/utils';

const ScrollToTop = () => {
  return (
    <Box
      sx={{
        width: 60,
        height: 60,
        borderRadius: '50%',
        position: 'fixed',
        right: '18px',
        bottom: { md: '10%', xs: 0 },
        background: palette.dark.background.white,
        display: { md: 'flex', xs: 'none' },
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
        '&:hover': {
          background: palette.dark.background.support_white,
        },
      }}
      onClick={scrollToTop}
    >
      <Image priority src={backToTop} alt="back to top" />
    </Box>
  );
};

export default ScrollToTop;
