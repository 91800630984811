import { i18n } from 'next-i18next';

import { META_STORAGE_KEY } from '@/constants';
import { removeItem } from '@/services/storage';

export const calculateTotalPage = (pageSize, total) => {
  const totalPage = total / pageSize;
  return totalPage > parseInt(totalPage, 10)
    ? parseInt(totalPage, 10) + 1
    : parseInt(totalPage, 10);
};

export const scrollToTop = () => {
  if (!(typeof window !== 'undefined')) return;
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const scrollToTopNoSmooth = () => {
  if (!(typeof window !== 'undefined')) return;
  window.scrollTo({ top: 0, behavior: 'instant' });
};

export const scrollToBottom = () => {
  if (!(typeof window !== 'undefined')) return;
  window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: 'smooth' });
};

export const formatToVndCurrency = (value) => {
  return new Intl.NumberFormat('vi-VN').format(value);
};

export const clearNewsStorage = () => {
  removeItem(META_STORAGE_KEY.NEWS);
};

export function hasChildren(item) {
  const children = item?.data;

  if (!children || !Array.isArray(children) || children?.length === 0) {
    return false;
  }

  return true;
}

export const convertToSlug = (text) => {
  return text
    ?.toLowerCase()
    ?.replace(/.*?[:()/:;.?\\[\]\-_+"|,]+/g, '') // loại bỏ chuỗi từ kí tự đặc biệt trở về trước
    ?.trim() // loại bỏ khoảng trắng thừa
    ?.replace(/\s+/g, '-'); // thay thế khoảng trắng bằng dấu "-"
};

export const pathNews = () => {
  return i18n?.language === 'vi' ? '/tin-tuc' : '/news';
};

export const pathPolicy = () => {
  return i18n?.language === 'vi' ? 'dieu-khoan' : 'policy';
};

export const pathServices = () => {
  return i18n?.language === 'vi' ? 'dich-vu' : 'services';
};

export const convertHash = (text) => {
  if (!text) {
    return '';
  }

  // Chuyển về chữ thường
  text = text.toLowerCase();

  // Loại bỏ dấu tiếng Việt
  text = text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

  // Loại bỏ các ký tự đặc biệt và khoảng trắng thừa
  text = text
    .replace(/[^\w\s-]/g, '') // Loại bỏ các ký tự không phải chữ, số, khoảng trắng, hoặc dấu "-"
    .trim() // Loại bỏ khoảng trắng ở đầu và cuối chuỗi
    .replace(/\s+/g, '-'); // Thay thế khoảng trắng bằng dấu "-"

  return text;
};

export const getMaxHeightElements = (elementList) => {
  let maxHeightElements = 0;
  Array.from(elementList).forEach((element) => {
    const elementHeight = element.offsetHeight;
    maxHeightElements = Math.max(maxHeightElements, elementHeight);
  });
  return maxHeightElements;
};

export const hasChildrenMenu = (item) => {
  const { items: children } = item;

  if (!children || !Array.isArray(children) || children?.length === 0) {
    return false;
  }

  return true;
};
