import React, { useMemo, useState } from 'react';

import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { List, ListItem, ListItemText, Collapse, IconButton } from '@mui/material';
import { useRouter } from 'next/router';

import { pathPolicy } from '@/utils';

const NestedMenuPolicy = ({ data, basePath = `/${pathPolicy()}` }) => {
  const [openItems, setOpenItems] = useState({});
  const router = useRouter();
  const handleToggle = (item, level) => {
    const newOpenItems = { ...openItems };
    newOpenItems[`${level}-${item}`] = !newOpenItems[`${level}-${item}`];
    setOpenItems(newOpenItems);
  };

  const isSelectedItem = useMemo(() => {
    const { policyId = [] } = router.query;
    if (policyId?.length === 1) {
      return policyId?.[0];
    }
    if (policyId?.length === 2) {
      return policyId?.[1];
    }
    if (policyId?.length === 3) {
      return policyId?.[2];
    }
    return null;
  }, [router.query]);

  const findSlugs = (slug) => {
    let result = null;
    data.forEach((item) => {
      if (item.attributes.slug === slug) {
        result = {
          parentSlug: item.attributes.slug,
          childSlug: null,
        };
      }
      if (item.attributes.policy_types?.data.length) {
        item.attributes.policy_types.data.forEach((child) => {
          if (child.attributes.slug === slug) {
            result = {
              parentSlug: item.attributes.slug,
              childSlug: child.attributes.slug,
            };
          }
          if (child.attributes.policy_details?.data.length) {
            child.attributes.policy_details.data.forEach((grandChild) => {
              if (grandChild.attributes.slug === slug) {
                result = {
                  parentSlug: item.attributes.slug,
                  childSlug: child.attributes.slug,
                };
              }
            });
          }
        });
      }
    });
    return result;
  };

  const handleClick = (slug, level) => {
    const { parentSlug, childSlug } = findSlugs(slug);
    if (level === 0) {
      router.push(`/${basePath}/${slug}`);
    }

    if (level === 1) {
      router.push(`/${basePath}/${parentSlug}/${slug}`);
    }
    if (level === 2) {
      router.push(`/${basePath}/${parentSlug}/${childSlug}/${slug}`);
    }
  };

  const renderMenu = (items, level = 0) => {
    let fontSize = '14px';
    if (level === 0) {
      fontSize = '16px';
    } else if (level === 2) {
      fontSize = '12px';
    }
    return (
      <List>
        {items?.map((item) => (
          <React.Fragment key={item.id}>
            <ListItem
              button
              onClick={() => handleToggle(item.id, level)}
              sx={{
                paddingLeft: `${level * 10}px`,
                fontWeight: 700,
              }}
            >
              <ListItemText
                primaryTypographyProps={{
                  style: {
                    color: isSelectedItem === item.attributes.slug ? '#0371D0' : '#101113',
                    fontSize,
                    fontWeight: level === 0 ? '700' : 'normal',
                    fontFamily: 'SVN-Gotham',
                  },
                }}
                primary={item.attributes.name}
                onClick={() => handleClick(item.attributes.slug, level)}
              />
              {(!!item.attributes.policy_types?.data.length ||
                !!item.attributes.policy_details?.data.length) && (
                <IconButton>
                  {openItems[`${level}-${item.id}`] ? <ArrowDropUp /> : <ArrowDropDown />}
                </IconButton>
              )}
            </ListItem>

            {(item.attributes.policy_types?.data || item.attributes.policy_details?.data) && (
              <Collapse in={openItems[`${level}-${item.id}`]} timeout="auto" unmountOnExit>
                {renderMenu(
                  item.attributes.policy_types?.data
                    ? item.attributes.policy_types.data.concat(
                        item.attributes.policy_details?.data || []
                      )
                    : item.attributes.policy_details?.data,
                  level + 1
                )}
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
    );
  };

  return <div>{renderMenu(data)}</div>;
};

export default NestedMenuPolicy;
