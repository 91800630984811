export const getBgColorByLevel = (level, open, theme) => {
  switch (level) {
    case 0:
      return open ? theme?.palette?.text?.blue : 'white';
    case 1:
      return '#F3F3F3';
    case 2:
      return '#E9E9E9';
    default:
      return '#dddddd';
  }
};

export const getTextColorByLevel = (level, open, theme) => {
  switch (level) {
    case 0:
      return open ? theme?.palette?.text?.white : theme?.palette?.text?.navbar_mobile;
    default:
      return open ? theme?.palette?.text.active : theme?.palette?.text?.navbar_mobile;
  }
};

export const getWeightByLevel = (level) => {
  switch (level) {
    case 0:
      return 600;
    case 1:
      return 500;
    case 2:
      return 400;
    default:
      return 400;
  }
};

export const getMinHeightByLevel = (level) => {
  switch (level) {
    case 0:
      return '50px';
    case 1:
      return '38px';
    case 2:
      return '30px';
    default:
      return '30px';
  }
};

export const handleCreateActiveId = (value) => {
  return `${value?.id}-${value?.slug}`;
};

export const handleCreatePath = (prefixPath, menuItem) => {
  return `${prefixPath}/${menuItem?.attributes?.slug}`;
};
