import { useEffect, useMemo, useState } from 'react';

import { Popper } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { trim } from 'lodash';
import { useRouter } from 'next/router';

import { useDebounce } from '@/hooks';
import useResponsive from '@/hooks/useResponsive';

export default function InputSearch({
  handleFetchData,
  label,
  sx,
  menu,
  dataTabs,
  handleCloseMenu,
}) {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const { searchDebounce } = useDebounce();
  const router = useRouter();
  const isDesktop = useResponsive('up', 'sm');
  const listService = useMemo(() => {
    return dataTabs?.categories?.reduce((accumulator, category) => {
      if (category.items) {
        accumulator.push(...category.items);
      }
      return accumulator;
    }, []);
  }, [dataTabs]);
  const handleInputChange = (event) => {
    setInputValue(event?.target?.value || '');
    searchDebounce(trim(event?.target?.value) || '');
  };

  const handleSelectProduct = (_, product) => {
    if (product?.attributes?.slug) {
      router.push(`/dich-vu/${product?.attributes?.slug || ''}`);
    }
    if (product?.slug) {
      router.push(`/dich-vu/${product?.slug || ''}`);
      handleCloseMenu();
      const basicMenu = document.getElementById('basic-menu');
      basicMenu.style.display = 'none';
    }
  };

  useEffect(() => {
    setInputValue('');
  }, [router.query]);

  return (
    <Autocomplete
      inputValue={inputValue}
      sx={menu ? { width: '100%', pr: 2 } : { width: isDesktop ? 350 : 200, ...sx }}
      freeSolo
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={handleSelectProduct}
      isOptionEqualToValue={(option, value) => option?.title === value?.title}
      getOptionLabel={(option) => option?.attributes?.name || option?.title || ''}
      options={listService || []}
      ListboxProps={{
        className: 'myCustomMenuList',
      }}
      // eslint-disable-next-line react/no-unstable-nested-components
      PopperComponent={(props) => (
        <Popper
          {...props}
          style={{ width: isDesktop ? `calc(66.6667% - 23px)` : '200px', zIndex: 9999 }}
          placement="bottom-start"
        />
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              setInputValue(e?.target?.value?.trim());
              handleFetchData(e.target.value);
            }
          }}
          onChange={handleInputChange}
          label={label}
          size="small"
          sx={{
            '.MuiAutocomplete-inputRoot': {
              paddingRight: '8px !important',
            },
          }}
        />
      )}
    />
  );
}
