import en from '@/assets/images/en.svg';
import prize1 from '@/assets/images/prize-1.png';
import prize20 from '@/assets/images/prize-20.png';
import prize21 from '@/assets/images/prize-21.png';
import vi from '@/assets/images/vi.svg';

export const ACCESS_TOKEN = 'token';

export const REFRESH_TOKEN = 'refresh_token';

export const REGION_ID = 'region_id';

export const PROJECT_ID = 'project_id';

export const httpMethod = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const LOGIN_URL = 'login';

// 'Trang chủ', 'Sản Phẩm', 'Tin tức', 'Về chúng tôi'

export const LOGO_SIZE = {
  MOBILE_WIDTH: 150,
  MOBILE_HEIGHT: 36,
  DESKTOP_WIDTH: 180,
  DESKTOP_HEIGHT: 43,
};

export const HEADER = {
  MOBILE_HEIGHT: 56,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 102,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 350,
  DASHBOARD_COLLAPSE_WIDTH: 0,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const THEME_DARK = 'dark';

export const SORT_POST_VALUES = {
  desc: 'createdAt:desc',
  asc: 'createdAt:asc',
  titleDesc: 'title:desc',
  titleAsc: 'title:asc',
};

export const TIME_REVALIDATE = 1 * 60;

// FORMAT DATE
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'YYYY/MM/DD HH:mm:ss';

// COLOR
export const TEXT_PRIMARY_COLOR = '#0E95F0';
export const PRIMARY_COLOR = '#0082D6';

// CONTENT NEWS TYPE
export const contentType = {
  KNOWLEDGE: 'KNOWLEDGE',
  CASESTUDY: 'CASESTUDY',
  EVENT: 'EVENT',
};

export const assetKey = {
  EBOOK: 'cc26430e2953e1e8eccb1d193fc9e68a1568e8b2e8fc167766c10896e2cc1b5e',
  ADS: '8a621fcdf1f87ca75f94d3e64031e79957da332988cf472d58a0f079c9f31c55',
  NEWS: '21f2417c33f3234c4fe66b0ed406b1649b1ff9fdbfa66112369cc7861c9ade06',
};

export const MENU_COLOR = '#505050 !important';

export const MENU_HOVER_COLOR = '#686868 !important';

export const DEBOUNCE_TIME = 700;

export const META_STORAGE_KEY = {
  NEWS: 'news',
};

export const newsItems = [
  { label: 'news_option_1', path: 'tin-tuc-va-su-kien' },
  { label: 'news_option_2', path: 'cloud-blog' },
  // { label: 'news_option_3', path: 'case-study' },
  { label: 'news_option_4', path: 'ebook' },
];

export const languageList = [
  { value: 'en', label: 'EN', icon: en },
  { value: 'vi', label: 'VI', icon: vi },
];

export const menuDataType = {
  NEWS: 'NEWS',
  OTHER: 'OTHER',
};

export const productIdInfo = {
  OVERVIEW: 'overview',
  BENEFIT: 'benefit',
  FEATURE: 'features',
  PRICING: 'pricing',
  DOCUMENT: 'document',
};

export const promotionItems = [
  { label: 'Promotion detail 1', path: 'promotion-detail-1' },
  { label: 'Promotion detail 2', path: 'promotion-detail-2' },
];

export const AboutUs = {
  OVERVIEW: 'understanding_cmc_cloud',
  BENEFIT: 'customers_global_network',
  FEATURE: 'prize',
  PRICING: 'development_history',
};

export const Prize = [
  {
    attributes: {
      image: {
        data: [
          {
            attributes: {
              url: prize1,
            },
          },
        ],
      },
    },
  },
  {
    attributes: {
      image: {
        data: [
          {
            attributes: {
              url: prize20,
            },
          },
        ],
      },
    },
  },
  {
    attributes: {
      image: {
        data: [
          {
            attributes: {
              url: prize21,
            },
          },
        ],
      },
    },
  },
  {
    attributes: {
      image: {
        data: [
          {
            attributes: {
              url: prize21,
            },
          },
        ],
      },
    },
  },
  {
    attributes: {
      image: {
        data: [
          {
            attributes: {
              url: prize21,
            },
          },
        ],
      },
    },
  },
  {
    attributes: {
      image: {
        data: [
          {
            attributes: {
              url: prize21,
            },
          },
        ],
      },
    },
  },
  {
    attributes: {
      image: {
        data: [
          {
            attributes: {
              url: prize21,
            },
          },
        ],
      },
    },
  },
  {
    attributes: {
      image: {
        data: [
          {
            attributes: {
              url: prize21,
            },
          },
        ],
      },
    },
  },
];

export const supportContentData = [
  {
    name: 'Tư vấn, dùng thử dịch vụ',
    value: 'Tư vấn, dùng thử dịch vụ',
  },
  {
    name: 'Xử lý sự cố, hỗ trợ kỹ thuật',
    value: 'Xử lý sự cố, hỗ trợ kỹ thuật',
  },
  {
    name: 'Gia hạn hợp đồng',
    value: 'Gia hạn hợp đồng',
  },
  {
    name: 'Khiếu nại',
    value: 'Khiếu nại',
  },
  {
    name: 'Khác',
    value: 'Khác',
  },
];

export const areaData = [
  {
    name: 'Việt Nam',
    value: 'vietnam',
  },
  {
    name: 'Hàn Quốc',
    value: 'korea',
  },
];
export const branchData = [
  {
    name: 'Bank',
    value: 'bank',
  },
  {
    name: 'Soccer',
    value: 'soccer',
  },
];
export const productData = [
  {
    name: 'Cloud',
    value: 'cloud',
  },
  {
    name: 'Database',
    value: 'database',
  },
];

export const prefixPhones = [
  {
    name: 'Tên quốc gia',
    value: 'Mã vùng điện thoại',
  },
  {
    name: 'Vietnam',
    value: '+84',
  },
  {
    name: 'Afghanistan',
    value: '+93',
  },
  {
    name: 'Albania',
    value: '+355',
  },
  {
    name: 'Algeria',
    value: '+213',
  },
  {
    name: 'American Samoa',
    value: '+684',
  },
  {
    name: 'Andorra',
    value: '+376',
  },
  {
    name: 'Angola',
    value: '+244',
  },
  {
    name: 'Anguilla',
    value: '+1264',
  },
  {
    name: 'Antigua & Barbuda',
    value: '+1268',
  },
  {
    name: 'Argentina',
    value: '+54',
  },
  {
    name: 'Armenia',
    value: '+374',
  },
  {
    name: 'Aruba',
    value: '+297',
  },
  {
    name: 'Australia (Úc)',
    value: '+61',
  },
  {
    name: 'Austria (Áo)',
    value: '+43',
  },
  {
    name: 'Azerbaijan',
    value: '+994',
  },
  {
    name: 'Bahamas',
    value: '+1242',
  },
  {
    name: 'Bahrain',
    value: '+973',
  },
  {
    name: 'Bangladesh',
    value: '+880',
  },
  {
    name: 'Barbados',
    value: '+1246',
  },
  {
    name: 'Belarus',
    value: '+375',
  },
  {
    name: 'Bỉ',
    value: '+32',
  },
  {
    name: 'Belize',
    value: '+501',
  },
  {
    name: 'Benin',
    value: '+229',
  },
  {
    name: 'Bermuda',
    value: '+1441',
  },
  {
    name: 'Bhutan',
    value: '+975',
  },
  {
    name: 'Bolivia',
    value: '+591',
  },
  {
    name: 'Bosnia & Herzegovina',
    value: '+387',
  },
  {
    name: 'Botswana',
    value: '+267',
  },
  {
    name: 'Brazil',
    value: '+55',
  },
  {
    name: 'Brunei Darussalam',
    value: '+673',
  },
  {
    name: 'Bulgaria',
    value: '+359',
  },
  {
    name: 'Burkina Faso',
    value: '+226',
  },
  {
    name: 'Burundi',
    value: '+257',
  },
  {
    name: 'Cambodia (Campuchia)',
    value: '+855',
  },
  {
    name: 'Cameroon',
    value: '+237',
  },
  {
    name: 'Canada',
    value: '+1',
  },
  {
    name: 'Cape Verde',
    value: '+238',
  },
  {
    name: 'Cayman Islands',
    value: '+1345',
  },
  {
    name: 'Central African Republic',
    value: '+236',
  },
  {
    name: 'Chad',
    value: '+235',
  },
  {
    name: 'Chagos Archipelago',
    value: '+246',
  },
  {
    name: 'Chile',
    value: '+56',
  },
  {
    name: 'China (Trung Quốc)',
    value: '+86',
  },
  {
    name: 'Colombia',
    value: '+57',
  },
  {
    name: 'Comoros',
    value: '+269',
  },
  {
    name: 'Congo',
    value: '+242',
  },
  {
    name: 'Congo, Dem. Rep. of',
    value: '+243',
  },
  {
    name: 'Cook Islands',
    value: '+682',
  },
  {
    name: 'Costa Rica',
    value: '+506',
  },
  {
    name: 'Côte d’lvoire',
    value: '+225',
  },
  {
    name: 'Croatia',
    value: '+385',
  },
  {
    name: 'Cuba',
    value: '+53',
  },
  {
    name: 'Dominican Republic',
    value: '+5487',
  },
  {
    name: 'Czech Republic (Séc)',
    value: '+420',
  },
  {
    name: 'Denmark (Đan Mạch)',
    value: '+45',
  },
  {
    name: 'Djibouti',
    value: '+253',
  },
  {
    name: 'Dominica',
    value: '+1767',
  },
  {
    name: 'Cyprus',
    value: '+357',
  },
  {
    name: 'Ecuador',
    value: '+593',
  },
  {
    name: 'Egypt (DST',
    value: '+20',
  },
  {
    name: 'El Salvador',
    value: '+503',
  },
  {
    name: 'Equatorial Guinea',
    value: '+240',
  },
  {
    name: 'Estonia',
    value: '+372',
  },
  {
    name: 'Ethiopia',
    value: '+251',
  },
  {
    name: 'Faeroe Islands',
    value: '+298',
  },
  {
    name: 'Falkland Islands',
    value: '+500',
  },
  {
    name: 'Fiji',
    value: '+679',
  },
  {
    name: 'Finland (Phần Lan)',
    value: '+358',
  },
  {
    name: 'France (Pháp)',
    value: '+33',
  },
  {
    name: 'French Antilles',
    value: '+596',
  },
  {
    name: 'French Guiana',
    value: '+594',
  },
  {
    name: 'French Polynesia††',
    value: '+689',
  },
  {
    name: 'Gabon',
    value: '+241',
  },
  {
    name: 'Gambia',
    value: '+220',
  },
  {
    name: 'Georgia',
    value: '+995',
  },
  {
    name: 'Germany (Đức)',
    value: '+49',
  },
  {
    name: 'Ghana',
    value: '+233',
  },
  {
    name: 'Gibraltar',
    value: '+350',
  },
  {
    name: 'Greece (Hy Lạp)',
    value: '+30',
  },
  {
    name: 'Greenland',
    value: '+299',
  },
  {
    name: 'Grenada',
    value: '+1473',
  },
  {
    name: 'Guadeloupe',
    value: '+590',
  },
  {
    name: 'Guam',
    value: '+1671',
  },
  {
    name: 'Guatemala',
    value: '+502',
  },
  {
    name: 'Guinea',
    value: '+224',
  },
  {
    name: 'Guinea-Bissau',
    value: '+245',
  },
  {
    name: 'Guyana',
    value: '+592',
  },
  {
    name: 'Haiti',
    value: '+509',
  },
  {
    name: 'Honduras',
    value: '+504',
  },
  {
    name: 'Hong Kong',
    value: '+852',
  },
  {
    name: 'Hungary',
    value: '+36',
  },
  {
    name: 'Iceland',
    value: '+354',
  },
  {
    name: 'India (Ấn Độ)',
    value: '+91',
  },
  {
    name: 'Indonesia',
    value: '+62',
  },
  {
    name: 'Iran',
    value: '+98',
  },
  {
    name: 'Iraq',
    value: '+964',
  },
  {
    name: 'Ireland',
    value: '+353',
  },
  {
    name: 'Israel',
    value: '+972',
  },
  {
    name: 'Italy',
    value: '+39',
  },
  {
    name: 'Ivory Coast',
    value: '+225',
  },
  {
    name: 'Jamaica',
    value: '+1876',
  },
  {
    name: 'Japan (Nhật Bản)',
    value: '+81',
  },
  {
    name: 'Jordan',
    value: '+962',
  },
  {
    name: 'Kazakhstan',
    value: '+153',
  },
  {
    name: 'Kenya',
    value: '+254',
  },
  {
    name: 'Korea, North (Hàn Quốc)',
    value: '+850',
  },
  {
    name: 'Korea, South (Triều Tiên)',
    value: '+82',
  },
  {
    name: 'Kuwait',
    value: '+965',
  },
  {
    name: 'Kyrgyzstan',
    value: '+996',
  },
  {
    name: 'Laos (Lào)',
    value: '+856',
  },
  {
    name: 'Latvia',
    value: '+371',
  },
  {
    name: 'Lebanon',
    value: '+961',
  },
  {
    name: 'Lesotho',
    value: '+266',
  },
  {
    name: 'Liberia',
    value: '+231',
  },
  {
    name: 'Libya',
    value: '+218',
  },
  {
    name: 'Liechtenstein',
    value: '+423',
  },
  {
    name: 'Lithuania',
    value: '+370',
  },
  {
    name: 'Luxembourg',
    value: '+352',
  },
  {
    name: 'Macau',
    value: '+853',
  },
  {
    name: 'Macedonia',
    value: '+389',
  },
  {
    name: 'Madagascar',
    value: '+261',
  },
  {
    name: 'Malawi',
    value: '+265',
  },
  {
    name: 'Malaysia',
    value: '+60',
  },
  {
    name: 'Maldives',
    value: '+960',
  },
  {
    name: 'Mali',
    value: '+223',
  },
  {
    name: 'Malta',
    value: '+356',
  },
  {
    name: 'Marshall Islands',
    value: '+692',
  },
  {
    name: 'Martinique',
    value: '+596',
  },
  {
    name: 'Mauritania',
    value: '+222',
  },
  {
    name: 'Mauritius',
    value: '+230',
  },
  {
    name: 'Mexico',
    value: '+52',
  },
  {
    name: 'Midway Islands',
    value: '+808',
  },
  {
    name: 'Moldova',
    value: '+373',
  },
  {
    name: 'Monaco',
    value: '+377',
  },
  {
    name: 'Mongolia',
    value: '+976',
  },
  {
    name: 'Montenegro & Serbia',
    value: '+381',
  },
  {
    name: 'Montserrat',
    value: '+1664',
  },
  {
    name: 'Morocco',
    value: '+212',
  },
  {
    name: 'Mozambique',
    value: '+258',
  },
  {
    name: 'Myanmar (Burma)',
    value: '+95',
  },
  {
    name: 'Namibia',
    value: '+264',
  },
  {
    name: 'Nepal',
    value: '+977',
  },
  {
    name: 'Netherlands',
    value: '+31',
  },
  {
    name: 'Netherlands Antilles',
    value: '+599',
  },
  {
    name: 'New Caledonia',
    value: '+687',
  },
  {
    name: 'New Zealand',
    value: '+64',
  },
  {
    name: 'Nicaragua',
    value: '+505',
  },
  {
    name: 'Niger Republic',
    value: '+227',
  },
  {
    name: 'Nigeria',
    value: '+234',
  },
  {
    name: 'Northern Mariana Isl.',
    value: '+1670',
  },
  {
    name: 'Norway',
    value: '+47',
  },
  {
    name: 'Oman',
    value: '+968',
  },
  {
    name: 'Pakistan',
    value: '+92',
  },
  {
    name: 'Palau',
    value: '+680',
  },
  {
    name: 'Panama',
    value: '+507',
  },
  {
    name: 'Papua New Guinea',
    value: '+675',
  },
  {
    name: 'Paraguay',
    value: '+595',
  },
  {
    name: 'Peru',
    value: '+51',
  },
  {
    name: 'Philippines',
    value: '+63',
  },
  {
    name: 'Poland (Ba Lan)',
    value: '+48',
  },
  {
    name: 'Portugal (Bồ Đào Nha)',
    value: '+351',
  },
  {
    name: 'Puerto Rico',
    value: '+3726',
  },
  {
    name: 'Qatar',
    value: '+974',
  },
  {
    name: 'Reunion Island',
    value: '+262',
  },
  {
    name: 'Romania',
    value: '+40',
  },
  {
    name: 'Russia (Nga)',
    value: '+7',
  },
  {
    name: 'Rwanda',
    value: '+250',
  },
  {
    name: 'San Marino',
    value: '+378',
  },
  {
    name: 'Sใo Tom้ & Principe',
    value: '+239',
  },
  {
    name: 'Saudi Arabia',
    value: '+966',
  },
  {
    name: 'Senegal',
    value: '+221',
  },
  {
    name: 'Seychelles',
    value: '+248',
  },
  {
    name: 'Sierra Leone',
    value: '+232',
  },
  {
    name: 'Singapore',
    value: '+65',
  },
  {
    name: 'Slovak Republic',
    value: '+421',
  },
  {
    name: 'Slovenia',
    value: '+386',
  },
  {
    name: 'Solomon Islands',
    value: '+677',
  },
  {
    name: 'Somalia',
    value: '+252',
  },
  {
    name: 'South Africa (Nam Phi)',
    value: '+27',
  },
  {
    name: 'Spain (Tây Ban Nha)',
    value: '+34',
  },
  {
    name: 'Sri Lanka',
    value: '+94',
  },
  {
    name: 'St. Kitts & Nevis',
    value: '+1869',
  },
  {
    name: 'St. Lucia',
    value: '+1758',
  },
  {
    name: 'St. Vincents & Grenadines',
    value: '+1784',
  },
  {
    name: 'Sudan',
    value: '+249',
  },
  {
    name: 'Suriname',
    value: '+597',
  },
  {
    name: 'Swaziland',
    value: '+268',
  },
  {
    name: 'Sweden (Thụy Điển)',
    value: '+46',
  },
  {
    name: 'Switzerland (Thụy Sĩ)',
    value: '+41',
  },
  {
    name: 'Syria',
    value: '+963',
  },
  {
    name: 'Taiwan (Đài Loan)',
    value: '+886',
  },
  {
    name: 'Tajikistan',
    value: '+992',
  },
  {
    name: 'Tanzania',
    value: '+255',
  },
  {
    name: 'Thái Lan',
    value: '+66',
  },
  {
    name: 'Togo',
    value: '+228',
  },
  {
    name: 'Tonga',
    value: '+676',
  },
  {
    name: 'Trinidad & Tobago',
    value: '+1868',
  },
  {
    name: 'Tunisia',
    value: '+216',
  },
  {
    name: 'Turkey (Thổ Nhĩ Kì)',
    value: '+90',
  },
  {
    name: 'Turkmenistan',
    value: '+993',
  },
  {
    name: 'Turks & Caicos Islands',
    value: '+1649',
  },
  {
    name: 'Tuvalu',
    value: '+688',
  },
  {
    name: 'Uganda',
    value: '+256',
  },
  {
    name: 'Ukraine',
    value: '+380',
  },
  {
    name: 'United Arab Emirates (Ả Rập)',
    value: '+971',
  },
  {
    name: 'United Kingdom (Vương Quốc Anh)',
    value: '+44',
  },
  {
    name: 'United States (Mỹ)',
    value: '+1',
  },
  {
    name: 'Uruguay',
    value: '+598',
  },
  {
    name: 'Uzbekistan',
    value: '+998',
  },
  {
    name: 'Vanuatu',
    value: '+678',
  },
  {
    name: 'Venezuela',
    value: '+58',
  },
  {
    name: 'Virgin Islands, British',
    value: '+1284',
  },
  {
    name: 'Virgin Islands, U.S.',
    value: '+1340',
  },
  {
    name: 'Western Samoa',
    value: '+685',
  },
  {
    name: 'Yemen',
    value: '+967',
  },
  {
    name: 'Yugoslavia',
    value: '+381',
  },
  {
    name: 'Zaire',
    value: '+243',
  },
  {
    name: 'Zambia',
    value: '+260',
  },
  {
    name: 'Zimbabwe',
    value: '+263',
  },
];
export const newsBannerType = {
  NEWS_HEADER: 'news-header',
  NEWS_FOOTER: 'news-content-footer',
  NEWS_CONTENT_TOP: 'news-content-top',
  NEWS_CONTENT_BOTTOM: 'news-content-bottom',
};
