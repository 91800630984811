import React, { useCallback, useState } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import { useTheme } from '@mui/material/styles';
import Link from 'next/link';

import TabsMenu from '@/components/mega-menu/tab-menu';
import useHashChangeStart from '@/hooks/useHashChangeStart';
import useRouterChangeStart from '@/hooks/useRouterChangeStart';

const MegaMenu = ({ name, hrefLink, dataTabs }) => {
  const { palette } = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const closeMegaMenu = useCallback(() => setOpen(false), []);

  useRouterChangeStart(closeMegaMenu);
  useHashChangeStart(closeMegaMenu);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <Box
      height="100%"
      onMouseEnter={(e) => {
        handleOpen(e);
      }}
      onMouseLeave={(e) => {
        handleClose(e);
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          cursor: 'pointer',
          height: '100%',
          [`&:hover .menu-title`]: {
            color: palette.primary.main,
          },
          borderBottom: open ? `4px solid ${palette.primary.main}` : 'none',
          mx: '7px',
        }}
      >
        <Link href={`/${hrefLink}`} passHref>
          <Typography
            className="menu-title"
            sx={{
              marginBottom: open ? '-4px' : 0,
              color: palette.text.blue,
              fontFamily: 'SVN-Gotham, sanserif',
              display: 'flex',
              alignItems: 'center',
              textTransform: 'inherit',
              fontWeight: 450,
              '@media (min-width:991px) and (max-width: 1480px)': {
                fontSize: '14px',
              },
              '@media (min-width:1480px) ': {
                fontSize: '16px',
              },
              '&:hover .arrow-dropdown': {
                transform: 'rotate(-180deg)',
              },
            }}
          >
            {name}
            {!!dataTabs && (
              <KeyboardArrowDownIcon
                className="arrow-dropdown"
                sx={{
                  width: 20,
                  height: 20,
                  color: palette.text.blue,
                  transition: '0.2s',
                }}
              />
            )}
          </Typography>
        </Link>
      </Box>

      {!!dataTabs && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          marginThreshold={0}
          transitionDuration={0}
          disableScrollLock
          disablePortal
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          disableRestoreFocus
          open={open}
          hideBackdrop
          sx={{
            bottom: 'initial',
            '& .MuiMenu-list': {
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
          BackdropProps={{
            invisible: false,
          }}
          PaperProps={{
            style: {
              width: '100%',
              maxHeight: 'unset',
              maxWidth: 'unset',
              borderRadius: 'unset',
              background: 'unset',
              boxShadow: '0px 2px 4px rgb(0 0 0 / 10%)',
            },
          }}
        >
          <TabsMenu handleCloseMenu={handleClose} dataTabs={dataTabs} hrefLink={hrefLink} />
        </Menu>
      )}
    </Box>
  );
};

export default MegaMenu;
