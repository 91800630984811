import { alpha } from '@mui/material/styles';

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const PRIMARY = {
  main: '#002958',
  extra: '#26AAFF',
};

const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
};

const SUB = {
  0: '#000000',
  1: '#2C2C2C',
  2: '#696969',
  3: '#929292',
  4: '#F3F3F3',
  5: '#CFE4F1',
  6: '#CDCDCD',
  7: '#f1f1f1',
};

const BLUE = {
  100: '#CEE7FF',
  200: '#AFD4F6',
  300: '#64AFF4',
  400: '#49A2F5',
  500: '#1685EC',
  600: '#0D73D2',
  700: '#0B66BA',
  800: '#0371D0',
  900: '#001977',
};

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
};

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
};

const ERROR = {
  main: '#C50000',
};

const SUPPORT = {
  black: '#242424',
  blue: '#1061D4',
  cyan: '#169CFF',
  blue_light: '#BDE3FC',
  white: '#f5f5f5',
  grey: '#dfdfdf',
};

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#2B2B2B',
  900: '#161616',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
  1000: '#000000',
};
const DARK = {
  0: '#222222',
  1: '#333333',
  2: '#3E3E3E',
  3: '#4D4D4D',
  4: '#414141',
  5: '#242424',
  6: '#b7b7b7',
  7: '#a8a8a8',
  8: '#0000003b',
  9: '#666666',
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
  sub: SUB,
  blue: BLUE,
};

const palette = {
  light: {
    ...COMMON,
    mode: 'light',
    highlight: '#1C9AD6',
    text: {
      default: '#252525',
      primary: '#2C2E33',
      secondary: '#2C2E33 ',
      tertiary: '#909296',
      secondary_inverse: '#5C5F66',
      disabled: GREY[500],
      active: PRIMARY.extra,
      white: '#fff',
      blue: '#002958',
      tab: '#0082D6',
      subtitle: SUB[5],
      dark: SUB[0],
      navbar_mobile: SUB[0],
      title_aboutUs: '#002958',
      about_text: '#1F96D2',
      about_card: '#002958',
    },
    divider: DARK[2],
    background: {
      button: '#0082D6',
      default: '#fff',
      card: '#F1F3F5',
      primary: '#1C9AD6',
      dark: GREY[300],
      white: '#fff',
      menu_tab: '#F1F3F5',
      pricing: '#F1F3F5',
      swiper: BLUE[800],
      blue: BLUE[800],
      scrollbar: GREY[500],
      card_pricing: '#fff',
      footer: '#E9ECEF',
      input: '#F8F9FA',
      trial: '#002958',
      slide: '#269AFF',
      about_card: '#002958',
      news_video_card: '#002958',
    },
    border: {
      input: '#D9D9D9',
      navbar_active: BLUE[800],
      default: DARK[2],
      popup_border: DARK[8],
      card: '#DEE2E6',
      scrollbar: GREY[500],
      active: PRIMARY.extra,
      tab: '#CED4DA',
      activeTab: '#002958',
    },

    action: { active: GREY[600], ...COMMON.action },
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    highlight: '#1C9AD6',
    text: {
      default: GREY[0],
      primary: '#fff',
      secondary: GREY[500],
      secondary_inverse: '#fff',
      disabled: GREY[600],
      active: PRIMARY.extra,
      white: GREY[0],
      blue: BLUE[800],
      subtitle: SUB[5],
      black: SUB[1],
      navbar_mobile: SUB[0],
      title_aboutUs: '#fff',
      about_text: '#1F96D2',
      about_card: '#002958',
    },
    divider: DARK[2],
    background: {
      button: '#0082D6',
      default: SUB[0],
      card: DARK[5],
      primary: BLUE[800],
      dark: DARK[0],
      white: GREY[0],
      support_blue: SUPPORT.blue,
      support_white: SUPPORT.white,
      menu_tab: '#fff',
      pricing: GREY[800],
      swiper: '#fff',
      scrollbar_track: SUB[7],
      blue: BLUE[800],
      card_pricing: GREY[800],
      footer: DARK[5],
      scrollbar_thumb: DARK[6],
      scrollbar_thumb_hover: DARK[7],
      input: '#F8F9FA',
      trial: DARK[5],
      slide: '#269AFF',
      about_card: '#002958',
      news_video_card: '#002958',
    },
    action: { active: GREY[500], ...COMMON.action },
    border: {
      input: '#fff',
      navbar_active: BLUE[800],
      default: DARK[2],
      popup_border: DARK[8],
      card: '#393939',
      scrollbar: '#6b6b6b',
      active: PRIMARY.extra,
      mobile_menu: SUPPORT.grey,
    },
  },
};

export default palette;
