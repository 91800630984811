import { useMemo } from 'react';

import { Box, Collapse, Typography, useTheme } from '@mui/material';
import { useRouter } from 'next/router';

import ArrowIcon from '@/layouts/header/components/ArrowIcon';
import {
  getBgColorByLevel,
  getMinHeightByLevel,
  getTextColorByLevel,
  getWeightByLevel,
  handleCreateActiveId,
} from '@/utils';

const NavItem = ({ navItem, level, onClick, childName, parentIds, open, prefixPath, onClose }) => {
  const { title, slug, name } = navItem || {};
  const { push } = useRouter();
  const theme = useTheme();
  const hasChildren = !!navItem?.[`${childName}`]?.length;

  const childItems = useMemo(
    () => navItem?.[`${childName}`]?.[0]?.categories || navItem?.[`${childName}`] || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navItem?.[`${childName}`]]
  );

  const paddingLeftNavItem = level * 3 + 3; // each level - 3 unit MUI

  const handleClickOnMenu = (levelMenuItem) => {
    if (hasChildren) {
      onClick({ selectedItems: [navItem], parentIds });
    } else {
      // Dịch vu ( Service) có level = 2
      let path = '';
      if (levelMenuItem === 0) {
        path = `/${slug}`;
      } else if (levelMenuItem === 1) {
        path = `${prefixPath}#${slug}`;
      } else if (levelMenuItem === 2) {
        path = `${prefixPath}/${slug}`;
      }
      push(path);
      onClose();
    }
  };

  return (
    <>
      {level === 0 && (
        <Box height="1px" bgcolor={theme.palette.border.mobile_menu} mx={2} mt="-1px" />
      )}
      <Box
        onClick={() => handleClickOnMenu(level)}
        sx={{
          ...(open && { position: 'relative' }),
          bgcolor: getBgColorByLevel(level, open, theme),
          pl: paddingLeftNavItem,
          pr: 1,
          minHeight: getMinHeightByLevel(level),
          display: 'flex',
          alignItems: 'center',
          ...(hasChildren && { cursor: 'pointer' }),
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography
            component="span"
            fontSize={level === 0 ? '18px' : '14px'}
            fontWeight={getWeightByLevel(level)}
            sx={{
              color: getTextColorByLevel(level, open, theme),
            }}
          >
            {title || name}
          </Typography>
        </Box>

        {hasChildren && <ArrowIcon open={open} level={level} color="action" />}
      </Box>

      {hasChildren && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box py="5px" sx={{ backgroundColor: getBgColorByLevel(level + 1, open, theme) }}>
            {childItems?.map((item, key) => (
              <NavItem
                key={key}
                navItem={item}
                level={level + 1}
                onClick={onClick}
                childName={childName}
                parentIds={handleCreateActiveId(navItem)}
                open={item.open}
                prefixPath={prefixPath}
                onClose={onClose}
              />
            ))}
          </Box>
        </Collapse>
      )}
    </>
  );
};

export default NavItem;
