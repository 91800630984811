import { useEffect, useRef } from 'react';

import { useRouter } from 'next/router';

const ScrollableAnchor = ({ id, children }) => {
  const router = useRouter();
  const scrollTargetElementRef = useRef(null);

  useEffect(() => {
    const hashIndex = router.asPath.indexOf('#');
    if (hashIndex !== -1) {
      const hash = router.asPath.slice(hashIndex + 1);
      if (hash === id) {
        const scrollTargetElement = scrollTargetElementRef.current;
        const scrollY = window.scrollY || window.pageYOffset;
        const scrollTargetY = scrollTargetElement.getBoundingClientRect().top + scrollY;
        window.scrollTo({
          top: scrollTargetY,
          behavior: 'smooth',
        });
      }
    }
  }, [id, router.asPath]);

  return (
    <div id={id} ref={scrollTargetElementRef}>
      {children}
    </div>
  );
};

export default ScrollableAnchor;
