import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Grid,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
  keyframes,
} from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';

import { HEADER } from '@/constants';

// export const SectionWrapper = styled(Box)((props) => {
//   return {
//     boxShadow: 'none',
//     width: '100%',
//     marginRight: 'auto',
//     marginLeft: 'auto',
//     paddingLeft: props?.paddingLeft ?? '16px',
//     paddingRight: props?.paddingRight ?? '16px',
//     '@media (min-width: 576px)': {
//       maxWidth: '540px',
//     },
//     '@media (min-width: 768px)': {
//       maxWidth: '720px',
//     },
//     '@media (min-width: 992px)': {
//       maxWidth: '960px',
//     },
//     '@media (min-width: 1440px)': {
//       maxWidth: '1472px',
//     },
//   };
// });

export const SectionWrapper = styled(Box)((props) => {
  return {
    boxShadow: 'none',
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingLeft: props?.paddingLeft ?? '16px',
    paddingRight: props?.paddingRight ?? '16px',
    '@media (min-width: 576px)': {
      maxWidth: '540px',
    },
    '@media (min-width: 768px)': {
      maxWidth: '720px',
    },
    '@media (min-width: 992px)': {
      maxWidth: 'calc(100vw - 208px)',
      marginLeft: '104px',
      marginRight: '104px',
    },
    '@media (min-width: 1280px)': {
      maxWidth: 'calc(100vw - 240px)',
      marginLeft: '120px',
      marginRight: '120px',
    },
    '@media (min-width: 1440px)': {
      maxWidth: 'calc(100vw - 260px)',
      marginLeft: '130px',
      marginRight: '130px',
    },
    '@media (min-width: 1600px)': {
      maxWidth: 'calc(100vw - 384px)',
      marginLeft: '192px',
      marginRight: '192px',
    },
    '@media (min-width: 1800px)': {
      maxWidth: 'calc(100vw - 384px)',
      marginLeft: '192px',
      marginRight: '192px',
    },
  };
});

export const Title = styled(Typography)((props) => ({
  color: props?.color ?? props.theme.palette.text.blue,
  fontSize: props?.fontSize ?? '36px',
  marginBottom: '1rem',
  fontWeight: props?.fontWeight || '900',
  fontFamily: 'SVN-Gotham, sans-serif',
  letterSpacing: '-0.02125rem',
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: { md: '16px', xs: '14px' },
  lineHeight: '19px',
  color: theme.palette.text.secondary,
}));

export const ContentBox = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: '14px',
  lineHeight: '22px',
}));

export const Label = styled(Typography)((props) => ({
  fontSize: props?.fontSize ?? '18px',
  fontWeight: 700,
}));

export const StyledTextarea = styled(TextareaAutosize)(() => ({
  width: '100%',
  display: 'flex',
  padding: '8px',
  marginBottom: '20px',
  alignItems: 'flex-start',
  gap: '10px',
  flex: '1 0 0',
  alignSelf: 'stretch',
  borderRadius: 'var(--radius-radius-md, 6px)',
  border: '1px solid var(--borders-default, #DEE2E6)',
  background: 'var(--backgrounds-default, #FFF)',
  '&:hover': {
    border: '1px solid',
  },
  '&:focus-visible': {
    border: '1px solid',
    outline: 'none',
  },
}));

export const InputForm = styled(TextField)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '20px',
  gap: '8px',
  flexShrink: 0,
  alignSelf: 'stretch',
  borderRadius: 'var(--radius-radius-lg, 8px)',
  border: '1px solid var(--borders-default, #DEE2E6)',
  background: 'var(--backgrounds-default, #FFF)',
}));

export const SelectForm = styled(Select)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  alignSelf: 'stretch',
  borderRadius: '8px',
  border: '1px solid var(--borders-default, #DEE2E6)',
  background: 'var(--backgrounds-default, #FFF)',
}));

export const ContentLimitLineStyled = styled(Typography)((props) => ({
  display: '-webkit-box',
  WebkitLineClamp: props?.lineLimitText ? props.lineLimitText : '3',
  paddingBottom: 0,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  fontFamily: props?.fontFamily || 'inherit',
}));

export const StyledBurger = styled('button')`
  position: fixed;
  right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.8rem;
  height: 1.8rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: ${({ open }) => (open ? 1308 : 1303)};
  top: calc(${HEADER.MOBILE_HEIGHT / 2}px - 0.5rem);
  ${({ theme }) => theme.breakpoints.up('lg')} {
    top: calc(${HEADER.DASHBOARD_DESKTOP_HEIGHT / 2}px - 1rem);
  }
  span {
    width: 29px;
    height: 3px;
    background: ${({ theme }) => theme.palette.text.primary};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-of-type {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    :nth-of-type(2) {
      opacity: ${({ open }) => (open ? '0' : '1')};
      transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
    }

    :nth-of-type(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

export const StyledMenu = styled('nav')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  height: 100vh;
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1306;
  transition: transform 0.3s ease-in-out;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }
`;

export const DocumentItemWrapper = styled(Grid)((theme) => ({
  background: theme.palette.background.white,
  color: 'black',
  padding: '15px 30px',
  ':hover': {
    transform: 'scale(1.002) perspective(0px)',
    transitionDuration: '0.4s',
    boxShadow:
      '0px -2px 10px -5px rgba(0, 0, 0, 0.45), 0px 2px 10px -5px rgba(0, 0, 0, 0.45) !important',
  },
}));

export const rotateNinetyDeg = styled(keyframes)(() => ({
  '0%': {
    transform: 'rotate(90deg)',
  },
  '100%': {
    transform: 'rotate(0deg)',
  },
}));

// export const rotateNinetyDeg = keyframes`
//   "0%": {
//     transform: "rotate(0deg)",
//   },
//   "100%": {
//     transform: "rotate(90deg)",
//   }
// `;

export const PopupBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.background.white,
  color: theme.palette.text.black,
  boxShadow: 24,
  border: '0px !important',
  borderRadius: '20px !important',
}));

export const CloseIconPopup = styled(CloseIcon)(({ theme }) => ({
  position: 'absolute',
  color: theme.palette.text.white,
  cursor: 'pointer',
}));

export const TextFieldPopup = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.border.popup_border,
    },
    '&:hover fieldset': {
      borderColor: theme.palette.border.popup_border,
    },
  },
  input: {
    color: theme.palette.text.black,
    ':-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 100px #d3d5d6 inset',
      WebkitTextFillColor: theme.palette.text.black,
    },
  },
}));
