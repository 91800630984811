/* eslint-disable max-len */
import { Box, Divider, Grid, List, Typography } from '@mui/material';
import { experimentalStyled as styled, useTheme } from '@mui/material/styles';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import DMCA from '@/assets/images/DMCA.svg';
import FaceBookIcon from '@/assets/images/facebook.svg';
import LinkedInIcon from '@/assets/images/linkedIn.svg';
import YoututeIcon from '@/assets/images/youtube.svg';
import ZaloIcon from '@/assets/images/zalo-cmc.svg';
import Logo from '@/components/logo-component';
import ScrollableAnchor from '@/components/scroll-anchor';
import useResponsive from '@/hooks/useResponsive';
import { SectionWrapper, Title } from '@/styles/styled';

const RootStyle = styled('div')(() => ({
  boxShadow: 'none',
  backgroundColor: useTheme().palette.background.footer,
  padding: '50px 0',
}));

const ListItemTextStyled = styled('div')(() => ({
  fontSize: 16,
  fontWeight: 400,
  marginBottom: '10px',
  color: useTheme().palette.text.primary,
}));

const Footer = ({ footerData }) => {
  const { t } = useTranslation(['footer', 'pop-up']);
  const isDesktop = useResponsive('up', 'sm');
  const theme = useTheme();

  const { infoData } = footerData || [];

  const directLinksFooter = (data) => {
    const result = data?.map((item) => {
      return (
        <Link passHref key={item.id} href={item.attributes.url}>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: '400',
              color: theme.palette.text.default,
              marginBottom: '10px',
            }}
          >
            {item.attributes.title}
          </Typography>
        </Link>
      );
    });
    return result;
  };
  return (
    <ScrollableAnchor id={isDesktop ? 'signup-form' : null}>
      <RootStyle>
        <SectionWrapper>
          <Grid container mb={3} spacing={1}>
            {infoData?.length > 0 && (
              <>
                {infoData.map((item, index) => {
                  return (
                    <Grid key={index} item xs={12} md={3}>
                      <Title
                        fontWeight={700}
                        my={2}
                        color={theme.palette.main}
                        fontSize={18}
                        mb={2}
                      >
                        {item?.attributes?.title}
                      </Title>
                      <List component="div">
                        {directLinksFooter(item?.attributes?.footer_urls?.data)}
                      </List>
                    </Grid>
                  );
                })}
              </>
            )}
          </Grid>

          <Divider sx={{ borderColor: `${theme.palette.text.blue} !important` }} />

          <Grid container mt={5}>
            <Grid item xs={12} md={7} pb={2}>
              <Grid container className="flex mb-2">
                <Grid item xs={12} md={6}>
                  <Link passHref href="/">
                    <Logo style={{ width: '120px' }} />
                  </Link>
                  <Typography fontSize={14} mt={{ xs: 0, md: 5 }}>
                    © 2023, CMC Cloud. All rights reserved.
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ListItemTextStyled sx={{ mb: 0 }}>
                    {t('hotline')}:{' '}
                    <a
                      href="tel:19002010"
                      style={{
                        color: theme.palette.text.blue,
                        textDecoration: 'none',
                        fontWeight: 700,
                        fontFamily: 'SVN-Gotham, sanserif',
                      }}
                    >
                      1900 2010
                    </a>
                  </ListItemTextStyled>

                  <ListItemTextStyled>
                    {t('email')}:{' '}
                    <a
                      fontFamily="SVN-Gotham, sanserif"
                      style={{
                        color: theme.palette.text.blue,
                        fontWeight: 700,
                        fontFamily: 'SVN-Gotham, sanserif',
                      }}
                      href="mailto:support@cmccloud.vn"
                    >
                      support@cmccloud.vn
                    </a>
                  </ListItemTextStyled>
                  <Box className="flex">
                    <Box mt={2} display="flex" alignItems="center">
                      <Link
                        passHref
                        href="https://facebook.com/cmccloud.vn"
                        target="_blank"
                        style={{ marginRight: '10px' }}
                      >
                        <Image width={28} height={28} src={FaceBookIcon} alt="facebook" />
                      </Link>
                      <Link
                        passHref
                        href="https://www.linkedin.com/company/cmc-cloud"
                        target="_blank"
                        style={{ marginRight: '10px' }}
                      >
                        <Image
                          width={28}
                          height={28}
                          src={LinkedInIcon}
                          alt="linkedIn"
                          style={{ width: 'auto', height: 'auto' }}
                        />
                      </Link>
                      <Link
                        passHref
                        href="https://www.youtube.com/channel/UCCTtS8-37x4wfnfr_-fuZ2A"
                        target="_blank"
                        style={{ marginRight: '10px' }}
                      >
                        <Image
                          width={28}
                          height={28}
                          src={YoututeIcon}
                          alt="youtube"
                          style={{ width: 'auto', height: 'auto' }}
                        />
                      </Link>
                      <Link passHref href="https://zalo.me/cmccloud" target="_blank">
                        <Image width={28} height={28} src={ZaloIcon} alt="facebook" />
                      </Link>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5} sx={{ textAlign: { xs: 'left', md: 'right' } }}>
              <Link href="https://www.dmca.com/Protection/Status.aspx?ID=0a7fd896-7d19-421c-a76b-7100c2a809cb&refurl=https://cmccloud.vn/">
                <Image
                  alt="Business Registration Certificate"
                  src={DMCA}
                  width={84}
                  height={42}
                  style={{
                    marginLeft: 'auto',
                  }}
                />
              </Link>
              <Box fontSize={13} mt={5} sx={{ textAlign: { xs: 'left', md: 'right' } }}>
                <Typography
                  color={theme.palette.text.blue}
                  fontSize={13}
                  fontFamily="SVN-Gotham, sanserif"
                >
                  {t('business_registration_certificate')}
                </Typography>
                <Typography
                  fontFamily="SVN-Gotham, sanserif"
                  color={theme.palette.text.blue}
                  fontSize={13}
                >
                  {t('content_responsibility')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </SectionWrapper>
      </RootStyle>
    </ScrollableAnchor>
  );
};

export default Footer;
