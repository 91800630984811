import darkScrollbar from '@mui/material/darkScrollbar';

import palette from '@/theme/palette';

const darkScrollBar = darkScrollbar();

export const darkComponents = {
  MuiCssBaseline: {
    styleOverrides: () => ({
      body: {
        ...darkScrollBar,
        '&::-webkit-scrollbar': {
          width: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '10px',
          border: `1px solid ${palette.dark.background.scrollbar}`,
        },

        // header search
        '.myCustomMenuList::-webkit-scrollbar': {
          width: '4px',
        },

        // sidebar
        '.sidebar-document::-webkit-scrollbar-thumb': {
          backgroundColor: `${palette.dark.background.scrollbar} !important`,
          borderRadius: '16px',
          border: `1px solid ${palette.dark.background.scrollbar}`,
        },
        '.sidebar-document::-webkit-scrollbar': {
          background: 'transparent',
          width: '4px',
        },
      },
    }),
  },
};

export const lightComponents = {
  MuiCssBaseline: {
    styleOverrides: () => ({
      body: {
        '&::-webkit-scrollbar': {
          width: '13px',
          background: '#ffffff',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `${palette.light.background.scrollbar}`,
          borderRadius: '10px',
          border: `1px solid ${palette.light.border.scrollbar}`,
          opacity: 1,
        },

        // header search
        '.myCustomMenuList::-webkit-scrollbar': {
          width: '4px',
          background: '#ffffff',
        },
        '.myCustomMenuList::-webkit-scrollbar-thumb': {
          backgroundColor: palette.light.border.scrollbar,
          borderRadius: '10px',
          border: `1px solid ${palette.light.border.scrollbar}`,
        },

        // sidebar
        '.sidebar-document::-webkit-scrollbar-thumb': {
          backgroundColor: `${palette.light.background.scrollbar} !important`,
          borderRadius: '16px',
          border: `1px solid ${palette.light.border.scrollbar}`,
          opacity: 0,
        },
        '.sidebar-document::-webkit-scrollbar': {
          background: 'transparent',
          width: '4px',
        },
      },
    }),
  },
};
