import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Link from 'next/link';
import { useRouter } from 'next/router';

import palette from '@/theme/palette';

export default function BigSidebar({ allDocumentTypes, setIsShowAllSidebar }) {
  const router = useRouter();
  const theme = useTheme();

  const createParentHref = (slug) => {
    return `/document/${slug}`;
  };

  return (
    <Box
      onMouseEnter={() => setIsShowAllSidebar()}
      sx={{
        border: `1px solid ${theme.palette.border.card}`,
        background: theme.palette.background.card,
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        width: { xs: '100vw', md: '60vw' },
        height: '70vh',
        position: 'fixed',
        top: '157px',
        left: { xs: 0, md: '22vw' },
        overflowY: 'auto',
        p: 3,
        zIndex: 1,
      }}
      className="myCustomMenuList"
    >
      <Grid container spacing={2}>
        {allDocumentTypes?.[0]?.attributes?.detail?.slice(1).map((item, index) => {
          return (
            <Grid item xs={12} md={3} key={index} sx={{ py: 2 }}>
              <Typography sx={{ fontWeight: 'bold' }}>{item?.name}</Typography>
              {item?.service_details?.map((childItem, childIndex) => {
                return (
                  <Box
                    key={childIndex}
                    sx={{
                      cursor: 'pointer',
                      ':hover': {
                        color: palette.dark.text.blue,
                      },
                      background: theme.palette.background.card,
                      color:
                        router?.query?.productId?.[0] === childItem?.slug
                          ? theme.palette.text.blue
                          : theme.palette.text.default,
                    }}
                  >
                    <Link href={createParentHref(childItem?.slug)}>{childItem?.name}</Link>
                  </Box>
                );
              })}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
