import React from 'react';

import { useTheme } from '@mui/material/styles';
import Image from 'next/image';

import logoCloud from '@/assets/images/logo-cloud.svg';
import logoCloudBlue from '@/assets/images/logo-footer-blue.svg';

// eslint-disable-next-line react/display-name
const Logo = React.forwardRef((props, ref) => {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light' ? logoCloudBlue : logoCloud;
  return <Image ref={ref} alt="logo" src={isLight} {...props} />;
});

export default Logo;
