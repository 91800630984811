/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import PhoneIcon from '@mui/icons-material/Phone';
import { Box } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { getCookie } from 'cookies-next';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import logoCloudBlue from '@/assets/images/logo-blue-header.svg';
import logoCloudWhite from '@/assets/images/logo-cloud.svg';
import Burger from '@/components/burger';
import MegaMenu from '@/components/mega-menu';
import { NAVBAR } from '@/constants';
// import useResponsive from '@/hooks/useResponsive';
import HeaderSearch from '@/layouts/header/components/HeaderSearch';
import NavSectionVertical from '@/layouts/header/components/MobileMenu';
// import SelectLanguage from '@/layouts/header/components/SelectLanguage';
import { StyledMenu } from '@/styles/styled';

const Header = ({ headerData }) => {
  const { t } = useTranslation('header');
  const theme = useTheme();
  const router = useRouter();
  // const isDesktop = useResponsive('up', 'sm');
  const [mobileOpen, setMobileOpen] = useState(false);

  const container = typeof window !== 'undefined' ? () => window.document.body : undefined;

  const handleCloseMenu = () => {
    setMobileOpen(false);
  };

  const handleChangeRoutes = (value) => {
    window.open(value, '_blank');
  };

  const handleSearch = (searchValue) => {
    router.push({
      pathname: `/search`,
      query: { searchValue },
    });
  };

  useEffect(() => {
    handleCloseMenu();
  }, [router.asPath]);

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: 69,
        zIndex: { xs: 1305, md: 100 },
      }}
    >
      <CssBaseline />
      <AppBar
        position="static"
        sx={{
          background: theme.palette.background.default,
          '@media (min-width: 1600px)': { px: 5 },
        }}
      >
        <Toolbar sx={{ height: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              width: { xs: '100%', md: 'auto' },
              '@media (min-width: 1280px)': { mx: 2 },
              '@media (min-width: 1600px)': { mr: 5 },
            }}
          >
            <Link passHref href="/">
              <Image
                alt="CMC Cloud"
                width={80}
                src={theme.palette.mode === 'dark' ? logoCloudWhite : logoCloudBlue}
              />
            </Link>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex', gap: 2 },
              alignItems: 'center',
              height: '100%',
            }}
          >
            {headerData?.map((item) => (
              <MegaMenu
                key={item?.id}
                hrefLink={item?.slug}
                name={item?.title}
                dataTabs={item?.navbar_menus[0]}
              />
            ))}
          </Box>
          {/* Right navbar */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mr: { xs: 7, md: 0 },
              gap: 1,
              '@media (min-width: 1600px)': { gap: 2 },
            }}
          >
            <HeaderSearch label={t('search')} handleSearch={handleSearch} />

            <a href="tel:19002010">
              <PhoneIcon
                sx={{
                  color: theme.palette.text.default,
                  '&:hover': {
                    color: theme.palette.highlight,
                  },
                }}
              />
            </a>

            <Box
              sx={{
                flexGrow: 0,
                display: { xs: 'none', md: 'flex' },
                alignItems: 'center',
                gap: 2,
                '@media (min-width: 1600px)': { gap: 3 },
              }}
            >
              <Link
                href="/document"
                style={{
                  fontFamily: 'SVN-Gotham, sanserif',
                  color: theme.palette.primary.main,
                  fontWeight: 450,
                }}
              >
                Documentation
              </Link>
              <Link
                href={process.env.APP_SIGN_IN_URL}
                rel="noopener noreferrer"
                target="_blank"
                style={{
                  fontFamily: 'SVN-Gotham, sanserif',
                  color: theme.palette.primary.main,
                  fontWeight: 450,

                  textTransform: 'none',
                }}
              >
                {t('login')}
              </Link>
              <Button
                sx={{
                  px: 2,
                  color: theme.palette.text.white,
                  background: `${theme.palette.highlight} !important`,
                  borderRadius: '6px',
                  '&:hover': {
                    color: theme.palette.text.white,
                    background: `${theme.palette.background.blue} !important`,
                  },
                  textTransform: 'none',
                }}
                onClick={() =>
                  handleChangeRoutes(
                    `${process.env.APP_REGISTER_ACCOUNT_URL}${getCookie('gtm_campaign') || ''}`
                  )
                }
              >
                {t('sign_up')}
              </Button>
            </Box>
            {/* {isDesktop && <SelectLanguage />} */}
            {/* {process.env.NODE_ENV === 'development' && (
              <AppMode toggleThemeMode={toggleThemeMode} />
            )} */}
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <StyledMenu open={mobileOpen}>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleCloseMenu}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              width: { xs: '100vw !important', sm: NAVBAR.DASHBOARD_WIDTH },
              display: { xs: 'block', md: 'none' },
            }}
          >
            <NavSectionVertical
              navConfig={headerData}
              onClose={handleCloseMenu}
              childName="navbar_menus"
            />
          </Drawer>
        </StyledMenu>
        <Burger open={mobileOpen} setOpen={setMobileOpen} />
      </Box>
    </Box>
  );
};

export default Header;
