import { useEffect, useState } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import cookie from 'cookie';
import { setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { appWithTranslation, useTranslation } from 'next-i18next';

import MainLayout from '@/layouts';
import { getDocumentTypes, getFooter, getNavBar, getPolicySidebar } from '@/services/apis/strapi';
import { darkComponents, lightComponents } from '@/theme/components';
import palette from '@/theme/palette';
import '../styles/ck.css';
import '../styles/font.css';
import '../styles/globals.css';
import '../styles/partners.css';
import { pathPolicy } from '@/utils';

const darkTheme = createTheme({
  palette: palette.dark,
  components: darkComponents,
});

const lightTheme = createTheme({
  palette: palette.light,
  components: lightComponents,
});

function MyApp({ Component, pageProps, footerData, headerData }) {
  const [cachedFooterData, setCachedFooterData] = useState(footerData);
  const [cachedHeaderData, setCachedHeaderData] = useState(headerData);
  const [sidebarData, setSidebarData] = useState([]);
  const [themeMode, setThemeMode] = useState('light');

  const router = useRouter();
  const { i18n } = useTranslation();
  const {
    utm_medium: utmMedium,
    utm_source: utmSource,
    utm_campaign: utmCampainName,
    productId,
    policyId,
  } = router.query;

  const rootPath = router?.pathname?.split('/')?.[1];

  const handleGetDataHeaderFooter = async () => {
    try {
      const headerDataProductsNew = await getNavBar(i18n?.language);
      const footerDataNew = await getFooter(i18n?.language);

      const newFooterData = {
        infoData: footerDataNew,
      };

      if (!footerDataNew || !headerDataProductsNew) {
        return { notFound: true };
      }

      setCachedFooterData(newFooterData);
      setCachedHeaderData(headerDataProductsNew);
    } catch (error) {
      if (error?.response?.status === 404) {
        return { notFound: true };
      }
      throw error;
    }
    return null;
  };

  const handleFetchDataSidebarDocument = async (productSlug) => {
    if (productSlug) {
      try {
        const newSidebarData = await getDocumentTypes({ productSlug, locale: i18n?.language });
        setSidebarData(newSidebarData);
      } catch (error) {
        /* empty */
      }
    }
  };
  const handleFetchDataSidebarPolicy = async () => {
    try {
      const newSidebarData = await getPolicySidebar({ locale: i18n?.language });
      setSidebarData(newSidebarData);
    } catch (error) {
      /* empty */
    }
  };

  useEffect(() => {
    handleGetDataHeaderFooter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    if (utmMedium && utmSource && utmCampainName) {
      setCookie('__gtm_campaign_url', `${window.location.origin.toString()}${router.asPath}`);
      setCookie('gtm_campaign', router.asPath);
    }
  }, [utmMedium, utmSource, utmCampainName, router?.asPath]);

  const theme = themeMode === 'light' ? lightTheme : darkTheme;

  const toggleThemeMode = () => {
    const newThemeMode = themeMode === 'light' ? 'dark' : 'light';
    setThemeMode(newThemeMode);
    localStorage?.setItem('themeMode', newThemeMode);
  };

  useEffect(() => {
    handleFetchDataSidebarDocument(productId?.[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language, productId]);

  useEffect(() => {
    if (router?.pathname === `/${pathPolicy()}` || rootPath === pathPolicy()) {
      handleFetchDataSidebarPolicy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language, policyId]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MainLayout
        footerData={cachedFooterData}
        headerData={cachedHeaderData}
        sidebarData={sidebarData}
        toggleThemeMode={toggleThemeMode}
      >
        <Component {...pageProps} />
      </MainLayout>
    </ThemeProvider>
  );
}

MyApp.getInitialProps = async ({ ctx }) => {
  const { req, locale } = ctx;
  const cookies = cookie.parse(req?.headers?.cookie || '');

  let footerData;
  let headerData;

  try {
    headerData = await getNavBar(locale);
    footerData = await getFooter(locale);
    const newFooterData = {
      infoData: footerData,
    };

    return {
      cookies,
      footerData: newFooterData,
      headerData,
    };
  } catch (error) {
    return { notFound: true };
  }
};

export default appWithTranslation(MyApp);
