import { useEffect, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { useRouter } from 'next/router';
import { Flip, ToastContainer } from 'react-toastify';

import ScrollToTop from '@/components/scroll-to-top';
import useRouterChangeStart from '@/hooks/useRouterChangeStart';
import Footer from '@/layouts/footer/Footer';
import Header from '@/layouts/header/Header';
import { pathPolicy } from '@/utils';

import Sidebar from './sidebar';
import ThemeProvider from '../context/theme-provider';

import 'react-toastify/dist/ReactToastify.css';

const MainLayout = ({ children, footerData, headerData, sidebarData = [], toggleThemeMode }) => {
  const [isDocumentDetailPage, setIsDocumentDetailPage] = useState(false);
  const router = useRouter();
  const isPagePolicy = router?.pathname?.split('/')?.[1] === pathPolicy();

  useEffect(() => {
    if (
      (router.query?.productId?.length >= 1 && !isDocumentDetailPage) ||
      (router.query?.policyId?.length >= 1 && !isDocumentDetailPage) ||
      isPagePolicy
    ) {
      setIsDocumentDetailPage(true);
    } else if (!router.query?.productId && isDocumentDetailPage) {
      setIsDocumentDetailPage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query?.productId, router.query?.policyId, isPagePolicy]);

  const [previousUrl, setPreviousUrl] = useState('');

  useRouterChangeStart(() => setPreviousUrl(router?.pathname));

  return (
    <ThemeProvider value={{ headerData, sidebarData, previousUrl }}>
      <Header headerData={headerData} toggleThemeMode={toggleThemeMode} />
      <Box mt={{ xs: 7, md: 8.6 }} position="relative">
        {isDocumentDetailPage ? (
          <Grid container position="relative">
            <Grid item xs={12} md={2.5}>
              <Sidebar sidebarData={sidebarData} headerData={headerData} />
            </Grid>
            <Grid item xs={12} md={9.5}>
              {children}
            </Grid>
          </Grid>
        ) : (
          <Box>{children}</Box>
        )}

        <ScrollToTop />
        <ToastContainer theme="colored" hideProgressBar transition={Flip} />
        <Footer footerData={footerData} />
      </Box>
    </ThemeProvider>
  );
};

export default MainLayout;
