import { useEffect, useMemo, useState } from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { startCase } from 'lodash';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import useResponsive from '@/hooks/useResponsive';
import NestedMenuPolicy from '@/layouts/sidebar/NestedMenuPolicy';
import { getHomepageDocument } from '@/services/apis';
import palette from '@/theme/palette';
import { pathPolicy } from '@/utils';

import BigSidebar from '../components/big-sidebar';
import MenuItem from '../components/menu-level';

export default function Sidebar({ sidebarData }) {
  const [isShowAllSidebar, setIsShowAllSidebar] = useState(false);
  const [allServices, setAllServices] = useState([]);
  const { t } = useTranslation(['document']);
  const router = useRouter();
  const theme = useTheme();
  const productName = startCase(router?.query?.productId?.[0]?.replaceAll('-', ' '));
  const rootPath = router?.pathname?.split('/')?.[1];
  const isDesktop = useResponsive('up', 'sm');
  const isPagePolicy = useMemo(() => rootPath === pathPolicy(), [rootPath]);

  const getAllService = async () => {
    try {
      const response = await getHomepageDocument();
      setAllServices(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (router?.query?.productId) {
      getAllService();
      if (isShowAllSidebar) {
        setIsShowAllSidebar(true);
      }
    } else {
      setIsShowAllSidebar(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.query?.productId]);

  const menuSideBar = useMemo(() => {
    if (isPagePolicy && isDesktop) {
      return <NestedMenuPolicy data={sidebarData} />;
    }

    if (!isPagePolicy) {
      return sidebarData.map((item, key) => <MenuItem key={key} item={item} />);
    }
    return null;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.query?.productId, sidebarData]);

  let timer = 0;

  const handleHideBigMenu = () => {
    timer = setTimeout(() => {
      setIsShowAllSidebar(false);
    }, 200);
  };

  const handleShowBigMenu = () => {
    clearTimeout(timer);
    if (!isShowAllSidebar) {
      setIsShowAllSidebar(true);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        height: { xs: 'auto', md: '93vh' },
        overflowY: 'hidden',
        position: 'sticky',
        top: '10vh',
        left: 0,
        zIndex: 3,
        pl: 2,
        borderRight: `1px solid ${theme.palette.border.card}`,
      }}
    >
      <Box
        sx={{
          px: '16px',
        }}
        onMouseEnter={() => handleShowBigMenu()}
        onMouseLeave={() => handleHideBigMenu()}
      >
        <Typography
          variant="h5"
          fontWeight={700}
          fontSize={22}
          color={theme.palette.text.blue}
          sx={{
            py: 6,
            pl: '10px',
          }}
        >
          {isPagePolicy ? 'Điều khoản và chính sách' : t('document', { ns: 'document' })}
        </Typography>
        {!rootPath && (
          <>
            <Box
              sx={{
                p: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                border: `1px solid ${theme.palette.border.card}`,
              }}
            >
              <Typography
                fontWeight={700}
                fontSize={16}
                sx={{
                  color: theme.palette.text.blue,
                }}
              >
                {productName}
              </Typography>
              <MenuIcon
                size="small"
                sx={{
                  rotate: isShowAllSidebar ? '90deg' : 'inherit',
                  transition: '.2s ease',
                  color: palette.dark.background.blue,
                }}
              />
            </Box>
            <BigSidebar allDocumentTypes={allServices} setIsShowAllSidebar={handleShowBigMenu} />
          </>
        )}
      </Box>

      {/* menu sidebar */}
      <Box
        sx={{
          pl: '10px',
          height: { xs: 'auto', md: 'calc(100% - 168px)' },
          overflowY: 'auto',
        }}
        className="sidebar-document"
      >
        {menuSideBar}
      </Box>
    </Box>
  );
}
