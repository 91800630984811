import { useCallback, useEffect, useState } from 'react';

import { useTheme } from '@emotion/react';
import { Box, Divider, Grid } from '@mui/material';
import { getCookie } from 'cookies-next';
import { cloneDeep } from 'lodash';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { menuDataType } from '@/constants';
import useHashChangeStart from '@/hooks/useHashChangeStart';
import useRouterChangeStart from '@/hooks/useRouterChangeStart';
import palette from '@/theme/palette';
import { handleCreateActiveId, handleCreatePath, pathServices } from '@/utils';

import NavItem from '../NavItem';

const findMenuItemByUrl = (
  menuItems,
  url,
  arrResult,
  prefixPath,
  childName,
  listParentActive = []
) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const menuItem of menuItems) {
    if (
      menuItem.isFullPath
        ? url === menuItem?.attributes?.slug
        : url === handleCreatePath(prefixPath, menuItem)
    ) {
      // only apply for 3 level nested
      if (listParentActive?.length > 0) {
        // filter list parent item selected
        let newListParentActive = [];

        if (menuDataType.NEWS === menuItem?.attributes?.type) {
          newListParentActive = listParentActive.slice(
            Number(listParentActive?.[0]?.attributes?.navbar_menus?.data?.length) + 1
          );
        } else if (menuDataType.OTHER === menuItem?.attributes?.type) {
          newListParentActive = [];
        } else {
          newListParentActive = [
            listParentActive[0],
            listParentActive.find(
              (item) => item.id === menuItem?.attributes?.product_type?.data?.id
            ),
          ];
        }

        arrResult.splice(0, arrResult.length, ...[...newListParentActive, menuItem]);
      } else {
        arrResult.push(menuItem);
      }
      return;
    }
    if (menuItem?.attributes?.[childName]?.data?.length > 0) {
      listParentActive.push(menuItem);
      findMenuItemByUrl(
        menuItem?.attributes?.[childName]?.data,
        url,
        arrResult,
        prefixPath,
        childName,
        listParentActive
      );
    }
  }
};

function NavSectionVertical({ navConfig, childName, onClose, prefixPath = `/${pathServices()}` }) {
  const router = useRouter();
  const theme = useTheme();
  const { t } = useTranslation('header');

  const [listMenu, setListMenu] = useState(navConfig);
  const configOpenMenuItem = (menuItem, activeId, level, currentSelectedId) => {
    // nếu không có activeId -> trang home -> đóng các menu
    // nếu trùng tồn tại trong array active id mở nếu
    // nếu là cha của menu hiện tại -> mở nếu nó là parent của item hiện tại hoặc là item selected
    // các trường hợp còn lại thì đóng menu
    if (!activeId?.length) {
      menuItem.open = false;
    } else if (activeId.includes(handleCreateActiveId(menuItem))) {
      // menuItem.open can undefined not change this
      if (!menuItem.open || Number(menuItem.id) !== Number(currentSelectedId)) {
        menuItem.open = true;
      } else {
        menuItem.open = false;
      }
    } else {
      menuItem.open = false;
    }

    if (menuItem?.navbar_menus?.[0]?.categories?.length > 0) {
      menuItem?.navbar_menus?.[0]?.categories?.forEach((child) => {
        child.navbar_menus = child.items;
        return configOpenMenuItem(child, activeId, level + 1, child.id);
      });
    }
  };

  const handleClickMenuItem = ({ selectedItems, parentIds }) => {
    // need create unique list id selected
    const listSelectedIds = selectedItems.map((item) => handleCreateActiveId(item));
    const listActiveIds = parentIds ? [...listSelectedIds, parentIds] : listSelectedIds;

    const listMenuClone = cloneDeep(listMenu);
    const currentSelectedId = selectedItems?.[0]?.id.toString();

    listMenuClone?.forEach((nav) => {
      configOpenMenuItem(nav, listActiveIds, 0, currentSelectedId);
    });

    setListMenu(listMenuClone);
  };

  const updateActiveMenuItemWhenRouteChange = useCallback((url) => {
    const listActiveItem = [];
    findMenuItemByUrl(listMenu, url, listActiveItem, prefixPath, childName);
    handleClickMenuItem({ selectedItems: listActiveItem });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateActiveMenuItemWhenRouteChange(router.asPath);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useRouterChangeStart(updateActiveMenuItemWhenRouteChange);
  useHashChangeStart(updateActiveMenuItemWhenRouteChange);

  const handleChangeRoutes = (value) => {
    window.open(value, '_blank');
  };

  return (
    <Box
      sx={{
        position: 'relative',
        top: 70,
        borderBottom: `1px ${theme.palette.border.mobile_menu} solid`,
        zIndex: 1,
        width: '100vw',
        height: 'calc(100vh - 70px)',
      }}
    >
      <Box
        sx={{
          height: '100%',
          position: 'relative',
          overflow: 'auto',
          '::-webkit-scrollbar': {
            width: '5px',
          },
          '::-webkit-scrollbar-track': {
            background: palette.dark.background.scrollbar_track,
          },
          '::-webkit-scrollbar-thumb': {
            bgcolor: theme.palette.background.scrollbar_thumb,
          },
          '::-webkit-scrollbar-thumb:hover': {
            background: theme.palette.background.scrollbar_thumb_hover,
          },
        }}
        mb={1}
      >
        <Box flexGrow={1}>
          {listMenu?.map((navItem, key) => (
            <NavItem
              key={key}
              navItem={navItem}
              level={0}
              onClick={handleClickMenuItem}
              onClose={onClose}
              childName={childName}
              open={navItem.open}
              prefixPath={prefixPath}
            />
          ))}
        </Box>
        {/* <Box
          sx={{
            mt: 6,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <InputSearch
            label={t('search')}
            handleFetchData={handleSearchProduct}
            sx={{ width: '95%' }}
          />
        </Box> */}
        <Divider sx={{ my: 2, mx: 2 }} />
        <Grid container px={2} spacing={2} pt={2}>
          <Grid item xs={6}>
            <Box
              color="primary"
              sx={{
                border: '1px solid #646464 ',
                p: 1,
                textAlign: 'center',
                borderRadius: 1,
                color: 'text.primary',
                '&:hover': {
                  background: palette.dark.background.blue,
                },
                textTransform: 'none',
              }}
              variant="contained"
              onClick={() => handleChangeRoutes(process.env.APP_SIGN_IN_URL)}
            >
              {t('login')}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                border: '1px solid #0D73D2 ',
                p: 1,
                textAlign: 'center',
                borderRadius: 1,
                color: 'text.primary',
                background: palette.dark.background.blue,

                textTransform: 'none',
              }}
              variant="contained"
              onClick={() =>
                handleChangeRoutes(
                  `${process.env.APP_REGISTER_ACCOUNT_URL}${getCookie('gtm_campaign') || ''}`
                )
              }
            >
              {t('sign_up')}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default NavSectionVertical;
